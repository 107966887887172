import { Alert, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import img from "../../assets/Images/Grupo 859.svg"
import { Box } from "@mui/system";
import React from "react";
import { useStyles } from "./InvalidPromoCodeStyle.js";

const InvalidPromoCode = ({handleClick}) => {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={true}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={img} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText} align='center'>
        Invalid Promo Code
        </Typography>
        <DialogActions>
        <Button className={classes.AlertBtn} onClick={handleClick}>
            Back
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default InvalidPromoCode;
