import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  Wrapper: {
    display: "flex",
    justifyContent: "center",
    alighItems: "center",
    flexDirection: "column",
  },

  CardContainer: {
    width: "992.8px",
    paddingTop: "48px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    ["@media (max-width: 1200px)"]: {
      width: "auto",
    },
    ["@media (max-width: 1100px)"]: {
      width: "auto",
    },
    ["@media (max-width: 1000px)"]: {
      width: "auto",
    },
  },

  Text: {
    fontFamily: theme.fonts.family.tertiary,
    fontWeight: "300",
    lineHeight: "27.36px",
    "&.MuiTypography-root": {
      fontSize: "20px",
      textAlign: "left",
    },
  },

  ThirdCardText: {
    fontFamily: theme.fonts.family.tertiary,
    fontWeight: "300",
    lineHeight: "27.36px",
    "&.MuiTypography-root": {
      fontSize: "20px",
      textAlign: "center",
      color: theme.colors.primary.Darkblue,
    },
  },

  ButtonWrapper: {
    width: "100%",
    marginTop: "28.96px",
    marginBottom: "45.12px",
  },

  CardDivider: {
    "&.MuiDivider-root": {
      border: "2px solid #C6C6C6",
      background: "#C6C6C6",
    },
  },

  SecondButtonWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "45.12px",
    gap: "20.6px",

    ["@media (max-width: 1100px)"]: {
      flexWrap: "wrap",
      gap: "9.12px",
    },
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
    ["@media (max-width: 500px)"]: {
      display: "block",
    },
  },

  Button: {
    "&.MuiButtonBase-root": {
      height: "52.22px",
      width: "100%",
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: "600",
        letterSpacing: "0.2em",
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: "600",
        letterSpacing: "0.2em",
      },
      ["@media (max-width: 1000px)"]: {
        width: "100%",
      },
      ["@media (max-width: 500px)"]: {
        width: "100%",
      },
    },
  },
  Links: {
    textDecoration: "none",
  },
  SecondButton: {
    "&.MuiButtonBase-root": {
      height: "52.22px",
      width: "469.92px",

      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: "12.96px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
      ["@media (max-width: 1100px)"]: {
        margin: "0",
      },
      ["@media (max-width: 500px)"]: {
        width: "100%",
        marginBottom: 20,
      },
    },
  },

  ThirdButton: {
    "&.MuiButtonBase-root": {
      height: "52.22px",
      width: "469.76px",

      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: "12.96px",
        fontFamily: theme.fonts.family.primary,
        fontWeight: "600",
        letterSpacing: "0.2em",
      },
      ["@media (max-width: 1100px)"]: {
        margin: "0",
      },
      ["@media (max-width: 500px)"]: {
        width: "100%",
        marginBottom: 20,
      },
    },
  },
  Refer: {
    textDecoration: "none",
  },
}));
