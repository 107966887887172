import { createTheme } from "@mui/material/styles";
import "../font.css";

export default createTheme({
  fonts: {
    family: {
      primary: "gillsans !important",
      secondary: "avenirMedium !important",
      tertiary: "avenirLight !important",
      quaternary: "gillsansMedium !important",
    },
  },
  colors: {
    primary: {
      Black: "#000000 !important",
      Grey: "#706F6F",
      LightGrey: "#EDEDED",
      Darkblue: "#005FAA !important",
      LightBlue: "#0166FF",
    },
    secondary: {
      Orange: "#F07F3C",
      White: "#FFFFFF !important",
      btnColor: "#E9724A",
      Green: "#56AF31",
      LightOrange: "#FDE2CF",
    },
  },
});
