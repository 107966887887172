import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MonthlyIncomeContainer: {
    width: "100%",
    margin: "auto",
  },
  MonthlyIncomeHeader: {
    "& .MuiTypography-body1": {
      fontSize: "16.46px",
      fontWeight: "800",
      fontFamily: theme.fonts.family.secondary,
      lineHeight: "22.49px",
      letterSpacing: "0.1em",
      color: "#005FAA",
    },
  },
  SupplyIncome: {
    marginBottom: "15px",
    "& .MuiAutocomplete-input": {
      textAlign: "center",
      color: "#ffff",
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.tertiary,
    },
    "& .MuiAccordionSummary-root ": {
      backgroundColor: "#56AF31",
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.secondary,
    },
    "& .MuiAccordionSummary-content": {
      placeContent: "center",
    },
    "& .MuiAccordionDetails-root": {
      color: "unset",
      padding: "0px 8px",
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  paragraph: {
    color: "#ffff"
  },
  Other: {
    marginBottom: "26px",
    "& .MuiAccordionSummary-root ": {
      backgroundColor: "#706F6F",
    },
    "& .MuiTypography-body1": {
      fontSize: "13px",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      fontWeight: "500",
      fontFamily: theme.fonts.family.secondary,
    },
    "& .MuiAccordionSummary-content": {
      placeContent: "center",
    },
    "& .MuiAccordionDetails-root": {
      color: "unset",
      padding: "0px 8px",
    },
    "& .MuiSvgIcon-root": {
      fill: "white",
    },
  },
  Total: {
    width: "49%",
    marginTop: "20px",
    "& .MuiInputBase-root": {
      backgroundColor: "#D3E4F6",
    },
    "& .MuiInputLabel-root": {
      fontWeight: "800",
    },
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  RadioBtn: {
    width: "49%",
    marginBottom: "2px",
    ["@media (max-width: 600px)"]: {
      width: "100%",
    },
  },
  RadioLabel: {
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: "500",
    color: theme.colors.primary.Grey,
  },
  InputMaskCustom: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "white",
    //border: "1px solid #DADADA",
    fontSize: 13,
    padding: "10px 12px",
    letterSpacing: "0.1em",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),

    // "&:focus": {
    //   boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
    //   borderColor: theme.palette.primary.main,
    // },

  },
  errorMessage: {
    color: "red",
    margin: "8px 0px 0px 0px",
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400
  },
  otherErrorMessage: {
    "& .MuiTypography-body1": {
    color: 'red',
    margin: '8px 0px 0px 0px',
    fontSize: '13px',
    fontFamily: 'avenirLight !important',
    fontWeight: '400',
    marginLeft: '40px'
    }
  }
}));
