import React, { useRef } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { useStyles } from "./ScheduleAppointmentStyle3";
import Button from "../../Button/Button";
import Select from "../../Inputs/Select";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DatePicker from "../../Inputs/DatePicker";
import TimePicker from "../../Inputs/TimePicker";
import SalaryAssignment from "../../../assets/pdf/Salary Assignment.pdf";
import ScheduleApproved from "./ScheduleApproved";
import SheduleThankYou from "./SheduleThankYou";
import { useFormik, Field, FormikProvider } from "formik";
import { baseUrl } from "../../../baseUrl";
import { useState } from "react";
import { useEffect } from "react";
import { ScheduleRequestAppointments } from "../../../Store/Actions/ScheduleRequestAppointment";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { validationSchema } from "./validation";
import { getMinHours, hoursInterval, minDate, minTime } from "../../../Utils/useMinDate";
import moment from "moment";
import { toast, Toaster } from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";

const MakeAppointment = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const inputFile = useRef(null);
  const [events, setEvents] = useState([]);

  // // // const calendarID = process.env.REACT_APP_CALENDAR_ID;
  // // // const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  // // // const accessToken = process.env.REACT_APP_GOOGLE_ACCESS_TOKEN;

  const formik = useFormik({
    initialValues: {
      salary: null,
    },
    //  enableReintialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const countPage = useRef(0);
  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1;
      window.scroll(0, 0);
      return;
    }
    // const events = getEvents(calendarID, apiKey);
    // setEvents(events);
  }, []);
  useEffect(() => {
    console.log(events);
  }, [events]);

  const [valueTime, setValueTime] = useState("");
  const [upload, setupload] = useState("");
  const [salaryLetter, setSalaryLetter] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showScheduleThankyou, setShowScheduleThankyou] = useState(false);

  const [errorTime, setErrorTime] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  const [dataSchedule, setDataSchedule] = useState({
    branchId: "",
    date: minDate('14:00:00Z'),
    time: valueTime,
  });

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime });
  }, [valueTime]);

  const handleSubmit = async () => {
    if (dataSchedule.branchId === "" || dataSchedule.date === "") {
      return toast.error("Please complete all the required fields.");
    }
    if (errorTime || errorDate) return toast.error("Please select a valid date and time.");

    if (upload === "") return toast.error("Upload signed salary assignment letter.");

    setLoading(true)

    const values = {
      ...salaryLetter,
      branch: dataSchedule.branchId,
      appointmentDate: moment(dataSchedule.date).format(),
      appointmentTime: moment(dataSchedule.time).format(),
    };
    // console.log(values, "values");
    // return
    const responde = await dispatch(ScheduleRequestAppointments({ ...values })).then((res) => console.log(res));
    setShowScheduleThankyou(true);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.download = `${SalaryAssignment}`;
    link.href = SalaryAssignment;
    link.click();
  };

  // UPLOAD SALARY ASSIGNMENT
  const File = async (e) => {
    const file = e.target.files[0];
    setupload(file.name);
    const base64 = await convertBase64(file);
    const salaryLetter = base64.split(",")[1];
    setSalaryLetter({
      salaryLetter,
    });
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onButtonClick = () => {
    inputFile.current.click();
    formik.setFieldTouched("salary");
  };
  return (
    <Box>
      <Toaster />
      <Box>
        <ScheduleApproved />
      </Box>
      <FormikProvider value={formik}>
        <Box className={classes.ScheduleAppointmentContainer}>

          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={8}
          >
            <Button
              Title="Download the salary assignment template"
              variant="h7"
              className={classes.UploadFiles}
              endIcon={<FileDownloadIcon />}
              onClick={onDownload}
            />
            <Stack width={{ xs: "100%", sm: "100%" }} direction="column">
              <Button
                Title={"Upload signed salary assignment letter."}
                variant="h7"
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={onButtonClick}
              />
              <Field name="salary">
                {({ field, form, meta }) => (
                  <>
                    <input
                      {...field}
                      type="File"
                      accept="application/pdf,image/*"
                      onChange={(event) => {
                        form.setFieldValue(
                          "salary",
                          event.currentTarget.files[0]
                        );
                        File(event);
                      }}
                      value=""
                      style={{ display: "none" }}
                      ref={inputFile}
                      error={meta.touched && meta.error}
                    />
                    <Typography className={classes.UploadFileText}>
                      Only pdf documents are permitted with a maximum of 2MB
                    </Typography>
                    {meta.touched && meta.error ? (
                      <p className={classes.fileText} style={{ color: "red" }}>
                        {meta.error}
                      </p>
                    ) : (
                      meta?.value?.name && (
                        <p className={classes.uploadFileName}>
                          {`${String(meta.value.name).substring(0, 25)} ...`}
                          <span>
                            <ClearIcon
                              className={classes.deleteFileIcon}
                              onClick={() => {
                                formik.setFieldValue(field.name, null);
                                setSalaryLetter("");
                                setupload("");
                              }}
                            />
                          </span>
                        </p>
                      )
                    )}
                  </>
                )}
              </Field>
            </Stack>
          </Stack>
          <Stack width={{ xs: "100%", sm: "49.5%" }} mt={6}>
            <Select
              Label="SELECT YOUR PREFERRED BRANCH"
              placeholder="Dangriga Branch"
              style={{ backgroundColor: "#ffff" }}
              ApiUrl={`${baseUrl}/branches`}
              name="branchId"
              onChange={(e, val) =>
                setDataSchedule({
                  ...dataSchedule,
                  branchId: val?.id,
                  branchName: val?.name,
                })
              }
              required
            />
          </Stack>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
            mt={6}
          >
            <DatePicker
              Label="SELECT APPOINTMENT DATE"
              openTo="year"
              views={["year", "month", "day"]}
              style={{ backgroundColor: "#ffff" }}
              disablePast={true}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              disableFuture={false}
              value={dataSchedule.date}
              minDate={minDate('14:00:00Z')}
              onError={(error) => setErrorDate(error)}
              name="appointmentDate"
            />
            <TimePicker
              Label=" SELECT APPOINTMENT TIME"
              placeholder="8:00AM"
              style={{ backgroundColor: "#ffff" }}
              name="appointmentTime"
              appointmentDate={dataSchedule.time}
              // appointmentDate={dataSchedule.date}
              minTime={getMinHours(dataSchedule.date, hoursInterval)}
              // minTime={loadTime(moment().format())}
              onError={(error) => setErrorTime(error)}
              // setValueTime={setValueTime}
              onChange={(e) => {
                setDataSchedule({ ...dataSchedule, time: e });
                setValueTime(e)
                // console.log(e.target, "time is here");
              }}
            />
          </Stack>

          <Box className={classes.SubmitBtn}>
             { (loading && !showScheduleThankyou) && (
             <Box sx={{ display: "flex" }}>
              <CircularProgress />
              </Box>
              )}

            {(!showScheduleThankyou && !loading) ? (
              <Button Title="SUBMIT" onClick={handleSubmit} />
            ) : null}
          </Box>
        </Box>
        <Box>{showScheduleThankyou ? <SheduleThankYou /> : null}</Box>
      </FormikProvider>
    </Box>
  );
};
export default MakeAppointment;
