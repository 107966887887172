export const userData = [
  {
    id: 1,
    firstName: "Lincoln",
    middleName: "",
    lastName: "Todd",
    dateOfBirth: "21/06/1995",
    ssn: "123456789",
  },
]

export const userAddres = [
  {
    id: 1,
    address: "Belice",
    districtId: 1,
    urbanAreaId: 1,
  },
]
