import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useStyles } from "./Style";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@material-ui/core";
import moment from "moment";

export default function BasicTimePicker({
	placeholder,
	onChange,
	Label,
	minTime,
	setValueTime,
	onError = false,
	appointmentDate = moment("2018-01-01T12:00:00"),
	...props
}) {
	const [value, setValue] = React.useState(appointmentDate);
	const classes = useStyles();

	// const [min, setMin] = useState('2022-12-15T12:00:00')
	// const [max, setMax] = useState()

	// useEffect(() => {
	// 	console.log('MIN', min)
	// 	console.log('MAX', max)
	// }, [])
	


	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<Box className={classes.DateWrapper}>
				<InputLabel shrink htmlFor="bootstrap-input" className={classes.TimeLable}>
					{Label}
				</InputLabel>
				<TimePicker
					className={classes.TimePicker}
					value={appointmentDate}
					minTime={moment(minTime) || moment("2018-01-01T12:00:00")}
					maxTime={moment("2018-01-01T14:30")}
					onChange={onChange}
					onError={onError}
					// onChange={(newValue) => {
					// 	// console.log(moment(newValue).format('LT'))
					// 	// setValue(newValue);
					// 	setValueTime(newValue);
					// 	console.log(moment(appointmentDate).format('LT'))
					// }}
					minutesStep={30}
					renderInput={(params) => (
						<TextField
							{...props}
							fullWidth
							placeholder={placeholder}
							{...params}
							sx={{
								"& legend": { display: "none" },
								"& fieldset": { top: 0 },
								"& .MuiInputLabel-formControl": {
									display: "none",
								},
							}}
						/>
					)}
				/>
			</Box>
		</LocalizationProvider>
	);
}
