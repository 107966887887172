import React from "react"
import Box from "@mui/material/Box"
import Logo from "../../assets/HomePageImages/Logo.png"
import { useStyles } from "./HomeHeaderStyle"
import MenuIcon from "@mui/icons-material/Menu"
import { Link } from "react-router-dom"

const HomeHeader = () => {
  const classes = useStyles()
  return (
    <Box className={classes.MainContainer}>
      <Box className={classes.ContainerWrapper}>
        {/* <MenuIcon className={classes.MenuIcon} /> */}
        <Link to="/">
          <img src={Logo} alt="" className={classes.Logo} />
        </Link>
      </Box>
    </Box>
  )
}

export default HomeHeader
