import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import { useStyles } from "./CardStyle";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const SecondCard = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.CardContainer}>
        <Typography variant="subtitle1" className={classes.Text}>
          If you’re a{" "}
          <strong>
            Belize Bank Customer without online or mobile banking services,
          </strong> download the Belize Bank mobile banking application today and enroll.
        </Typography>
        <Box className={classes.SecondButtonWrapper}>
          <Link to="/EnrollNowPage" className={classes.Links}>
            <Button
              className={classes.SecondButton}
              Title="ENROLL NOW"
              variant="subtitle1"
              BtnVariant="contained"
            />
          </Link>
          <Link to="/EnrollLaterPage" className={classes.Links}>
            <Button
              className={classes.SecondButton}
              Title="ENROLL LATER"
              variant="subtitle1"
              BtnVariant="contained"
            />
          </Link>
        </Box>
      </Box>
      <Divider className={classes.CardDivider} />
    </>
  );
};

export default SecondCard;
