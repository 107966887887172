import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/system";
import { useStyles } from "./Style";
import InputLabel from "@mui/material/InputLabel";
import { Stack } from "@mui/system";
import moment from "moment";
import { useEffect } from "react";
import { minDate } from "../../Utils/useMinDate";

// const minDate = () => {
//   const now = moment(new Date())
//   const timeLimit = moment(`${moment(now).format('YYYY-MM-DD')}T${'15:00:00Z'}`)
//   const dif = timeLimit.diff(now)
//   if (!(dif >= 0)) return `${moment(now).add(1, 'day')}`
//   return moment(now)
// }

export default function ResponsiveDatePickers({
  onChange,
  value,
  minDate,
  onBlur,
  Title,
  error,
  Label,
  className,
  placeholder,
  disableFuture,
  disablePast,
  openTo = 'year',
  disableDay = true,
  onError,
  disabled = false,
  ...props
}) {
  const classes = useStyles();

  return (
    <Stack sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box className={classes.DateWrapper}>
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            className={classes.DateLable}
          >
            {Label}
          </InputLabel>
          <DatePicker
            className={classes.DatePicker}
            label="Date of birth"
            openTo={openTo}
            onBlur={onBlur}
            views={["year", "month", "day"]}
            inputFormat="DD/MM/yyyy"
            onChange={onChange}
            onError={onError}
            // minDate={minDate || ''}
            // minDate={`${minDate('14:00:00Z')}`}
            value={value}
            disabled={disabled}
            disableFuture={disableFuture}
            disablePast={disablePast}
            shouldDisableDate={(date) => disableDay ? moment(date).day() === 0 || moment(date).day() === 6 : false} // Deshabilitar sábados y domingos
            renderInput={(params) => (
              <TextField
                {...props}
                fullWidth
                {...params}
                className={[className].join(" ")}
                sx={{
                  "& legend": { display: "none" },
                  "& fieldset": { top: 0 },
                  "& .MuiInputLabel-formControl": {
                    display: "none",
                  },
                }}
              />
            )}
          />
        </Box>
      </LocalizationProvider>
      {error && <p className={classes.errorMessage}>{Title}</p>}
    </Stack>
  );
}
