import React from "react"
import AlreadyCustomer from "./AlreadyCustomer"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import { useStyles } from "./AlreadyCustomerStyle"
import Stack from "@mui/material/Stack"
import Button from "../Button/Button"
import Smile from "../../assets/Smile.png"
import { Link } from "react-router-dom"

const AlreadyCustomerContainer = ({ modal, ssnExist = false }) => {
  const classes = useStyles()
  return (
    <AlreadyCustomer ModalOne={modal}>
      <Box className={classes.MainContainer}>
        <Box className={classes.CardContainer}>
          <Box className={classes.IconWrapper}>
            <img src={Smile} alt="" className={classes.Smile} />
          </Box>
          <Box className={classes.Wrapper}>
            {ssnExist ? (
              <Typography variant="subtitle1" className={classes.Text}>
                <strong style={{color: "#F07F3C"}}>Congratulations!.</strong> <br />
                <strong> The validation was successful. </strong> <br />
                As a Belize Bank customer you are automatically enrolled in our loyalty program and enjoy lower interest rates. Login to the Belize Bank online banking or mobile banking app to continue with the loan application process.
              </Typography>
            ) : (
              <Typography variant="subtitle1" className={classes.Text}>
                Our data shows that you are already a
                <strong> Belize Bank Customer. </strong> Please continue with
                your request by logging into your online or mobile banking app.
              </Typography>
            )}

            <Stack className={classes.stackButton} direction="row">
              <a href="https://online.belizebank.com/#/auth/login" className={classes.Links}>
                <Button
                  Title="LOGIN NOW"
                  variant="subtitle1"
                  className={classes.Button}
                  BtnVariant="contained"
                />
              </a>
            </Stack>
          </Box>
        </Box>
      </Box>
    </AlreadyCustomer>
  )
}

export default AlreadyCustomerContainer
