import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  LoanContainer: {
    width: "850.4px",
    margin: "0 auto 161px auto",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
  },
}));
