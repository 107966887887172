import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './ScheduleAppointmentStyle3'
import Calendar from '../../../assets/Images/Calendar.png'
import { Typography } from '@material-ui/core'
import Button from '../../Button/Button'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import moment from 'moment'

const AppointmentPending = ({ customer, appointment }) => {
  const classes = useStyles()
  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouText}>
            <strong>
              Dear {`${customer?.firstName} ${customer?.lastName}`}
            </strong>{' '}
            <br />
            Your appointment has been successfully scheduled. Please visit
            {` ${appointment?.branchName}`} branch on{' '}
            {moment(appointment?.appointmentDate).format('DD-MM-YYYY')}{' at '}
            {moment(appointment?.appointmentTime).format('LT')} Thank you for
            giving us the opportunity to serve you.
          </Typography>
          <Box className={classes.ThankYouBtn}>
            <Link to="/ProductsAndServices" className={classes.Link}>
              <Button Title="THANK YOU" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AppointmentPending
