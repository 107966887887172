import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSelector } from "react-redux";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars() {
  const [open, setOpen] = React.useState(false);

  const Boolean = useSelector((state) => state.LetsMeetFormReducer.userExsits);

  const errorMessage = useSelector(
    (state) => state.LetsMeetFormReducer.snackBarMessages
  );

  useEffect(() => {
    setOpen(Boolean);
    setTimeout(() => {
      setOpen(false);
    }, 6000);
  }, [Boolean]);
  useEffect(() => {
    setOpen(Boolean);
    setTimeout(() => {
      setOpen(false);
    }, 6000);
  },[errorMessage])
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={3000}>
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
