export const errorToastStyle = {
  borderRadius: '4px',
  background: '#FF4C4C',
  color: '#FFFFFF',
  padding: '12px 16px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)'
}

export const iconTheme = {
  primary: '#fff',
  secondary: '#333'
}