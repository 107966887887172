import React, { useEffect } from "react"
import { Typography } from "@mui/material"
import Success from "../../../assets/Images/Success.png"
import Approved from "../../../assets/Images/Approved.png"
import Cross from "../../../assets/Images/Cross.png"
import { Box } from "@mui/system"
import { useStyles } from "./Styles"
import { useNavigate } from 'react-router-dom'

const InfoReferenceNumber = ({status}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  useEffect(() => {
    if (!status) navigate(-1)
  }, [status])

    const srcImage = (status === 4) ? Cross : Approved
  return (
    <Box m={6}>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={`${classes.ApprovedPoppupImage} ${(status === 4) && classes.Declined}`}>
            <img className={classes.ApprovedImage} src={srcImage} alt="logo" />
          </Box>
          {status === 4 && (
            <Typography className={classes.ApprovedText}>
              Your loan was <strong>not approved.</strong> If you wish to get
              more details about the reason, please contact us at
              bblonlineloans@belizebank.com
            </Typography>
          )}
          {status === 10 && (
            <Typography className={classes.ApprovedText}>
              Your loan has been <strong>Finalized</strong>
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default InfoReferenceNumber
