import moment from "moment"

export const hoursInterval = [
  `${moment().format('YYYY-MM-DD')}T08:00:00`,
  `${moment().format('YYYY-MM-DD')}T09:00:00`,
  `${moment().format('YYYY-MM-DD')}T10:00:00`,
  `${moment().format('YYYY-MM-DD')}T11:00:00`,
  `${moment().format('YYYY-MM-DD')}T12:00:00`,
  `${moment().format('YYYY-MM-DD')}T13:00:00`,
  `${moment().format('YYYY-MM-DD')}T14:00:00`,
  // `${moment().format('YYYY-MM-DD')}T15:00:00`,
]

export const minDate = (hour = '15:00:00Z') => {
  const nowDate = moment().format('YYYY-MM-DDTHH:mm:ss')

  const later = moment(`${moment(nowDate).format('YYYY-MM-DD')}T${hour}`)
  const dif = later.diff(`${nowDate}Z`) // ? later - now
  if (dif < 0) return `${moment(nowDate).add(1, 'day')}`
  return moment(nowDate)
}

const intervalHours = (hours, dateTime = []) => {
  // let h = ''
  for (let i=0; i < dateTime.length; i++) {
    const dif = moment(moment(hours).format('YYYY-MM-DDTHH:mm')).diff(dateTime[i]) / 60000
    
    if (dif < -60) {
      // console.log(`${dateTime[i]}`)
      return moment(dateTime[i]).format('YYYY-MM-DDTHH:mm')
    }
  }
  return null
}

export const minTime = (dateTime, hours = hoursInterval, hourMin = '08:00:00') => {
  const nowDate = moment().format('YYYY-MM-DDTHH:mm:ss')
  const dif = moment(moment(nowDate).format('YYYY-MM-DD')).diff(moment(dateTime).format('YYYY-MM-DD'))
  // console.log('Diferencias', dif) // ! si es el mismo dia la diferencia sera 0
  const min = `${moment(nowDate).format('YYYY-MM-DD')}T${hourMin}`
  if (dif !== 0) return min

  return intervalHours(dateTime, hours)

}

export const getMinHours = (dateTime, intervalHours = []) => {
  // console.log(isToday(dateTime))
  if (!isToday(dateTime)) return intervalHours[0]
  // console.log('la fecha es hoy')

  const limitTime = intervalHours.at(-1)
  if (timeExpired(limitTime)) return `${moment().format('YYYY-MM-DD')}T23:59:00` //! retornar una hora fuera de la horas permitidas
  // console.log('no ha llegado a la hora limite que es: ', new Date(limitTime).getHours())

  const minHour = getHourInInterval(intervalHours)
  // console.log('Son las: ', new Date().getHours(), 'Y la hora minima permitida deberia ser: ', new Date(minHour).getHours())
  return minHour
}

const isToday = (dateTime) => moment(dateTime).isSame(moment(), 'date')

const getHourInInterval = (intervalHours = []) => {
  for (let i = 0; i < intervalHours.length; i++) {
    if (!timeExpired(intervalHours[i])) return intervalHours[i]
  }
}

export const timeExpired = (limitTime) => {
  const limitHours = new Date(limitTime).getTime()
  const currentHours = new Date().getTime()
  return currentHours > limitHours
}

