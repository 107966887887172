import React, { useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import { useStyles } from "./CongratulationsStyle";
import SmilingEmoji from "../../assets/Images/SmilingEmoji.png";
import Button from "../Button/Button";

import { useNavigate, useLocation } from "react-router-dom";

const Congratulations = ({ status }) => {
  console.log(status, "statusstatus");
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Box className={classes.CongratulationsContainer}>
      <Box className={classes.CongratulationsBox}>
        <img className={classes.SmilingEmoji} src={SmilingEmoji} alert="" />
        <Typography className={classes.CongratulationsText}>
          Congratulations!
        </Typography>
        <Typography className={classes.ProcessText}>
          <strong>
            You have successfully completed your loan application process.
          </strong>
          <br />
          <br />
          We will start the review process and provide you with a prompt
          response.
        </Typography>
        <Button
          className={classes.ThankYouBtn}
          Title="THANK YOU"
          onClick={() => navigate("/ProductsAndServices")}
        />
      </Box>
    </Box>
  );
};

export default Congratulations;
