import Actions from "../Types";

const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  emailAddress: "",
  phone: "",
  ssn: "",
  dateOfBirth: "",
  address: "",
  districtId: "",
  urbanAreaId: "",
  educationId: "",
  homeStatusId: "",
  userExsits: false,
  isLoading: false,
  ssnExsits: 9,
  success: false,
  snackBarMessages: "",
  proof: {},
  accountMatchingStatus: 9,
};
const returnToInitialState = () => {
  return {
    firstName: "",
    middleName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    ssn: "",
    dateOfBirth: "",
    address: "",
    districtId: "",
    urbanAreaId: "",
    educationId: "",
    homeStatusId: "",
    userExsits: false,
    isLoading: false,
    ssnExsits: 9,
    success: false,
    snackBarMessages: "",
    proof: {},
    accountMatchingStatus: 9,
  }
}
const LetsMeetFormReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.LETSMEETFORM:
      return {
        ...state,
        ...payload,
      };
    case Actions.SSNEXISTS:
      return {
        ...state,
        ssnExsits: payload,
      };
    case Actions.USEREXSITS:
      return {
        ...state,
        userExsits: payload,
      };
    case Actions.LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case Actions.SUCCESS:
      return {
        ...state,
        success: true,
      };
    case Actions.SNACKBARMESSAGES:
      return {
        ...state,
        snackBarMessages: {message : payload},
      };
    case Actions.PROOF:
      return {
        ...state,
        proof: payload,
      };
    case Actions.CLEARSTATE :
        return returnToInitialState()
      default:
      return state;
  }
};

export default LetsMeetFormReducer;
