import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import LetsMeetFormReducer from "./Reducers/LetsMeetFormReducer";
import OtpValidation from "./Reducers/OtpValidationReducer";
import LoanRequestReducer from "./Reducers/LoanRequestReducer";
import GetMessagesReducer from "./Reducers/GetMessagesReducer";
import MakeScheduleReducer from "./Reducers/MakeScheduleReducer";
import ReferenceStatusReducer from "./Reducers/ReferenceStatusReducer";
import OtpEmailReducer from "./Reducers/OtpEmailReducer";
import ScheduleRequestReducer from "./Reducers/YourrLoanRequestScheduleAppReducer";
import RadioChecked from "./Reducers/RadioCheckedReducer";
import UploadDocumentsReducer from "./Reducers/UploadDocumentsReducer"
import referenceNumber from "../Pages/reference-number/store/index"
import assetsDeclaration  from '../Components/Forms/AssetsDeclaration/store'
import letsMeet from '../Components/Forms/LetsMeetForm/store'

const rootReducer = combineReducers({
  LetsMeetFormReducer: LetsMeetFormReducer,
  OtpValidation: OtpValidation,
  LoanRequestReducer: LoanRequestReducer,
  GetMessagesReducer: GetMessagesReducer,
  MakeScheduleReducer: MakeScheduleReducer,
  ReferenceStatusReducer: ReferenceStatusReducer,
  OtpEmailReducer: OtpEmailReducer,
  ScheduleRequestReducer: ScheduleRequestReducer,
  RadioChecked: RadioChecked,
  UploadDocumentsReducer: UploadDocumentsReducer,
  // * Nuevos reducers
  referenceNumber,
  assetsDeclaration,
  letsMeet
})

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
})

// const ConfigureStore = () => {


//   return { store };
// };

export default store;
