import React, { useEffect, useState } from 'react'
import { Interceptor } from '../../../Utils/Interceptor'
import { Box } from '@mui/material'
import ArrowButton from '../../ArrowButton/ArrowButton'
import MissingDocsUpload from './missingDocsUpload'
import MakeAppointment from './MakeAppointment'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Actions from '../../../Store/Types'
import Congratulations from '../../Congratulations/Congratulations'
import AppointmentPending from './AppointmentPending'

const ScheduleAppointment = () => {
  const AxiosInterceptor = Interceptor()
  const [status, setStatus] = useState(undefined)
  const [Files, setFiles] = useState([])
  const [appointmentData, setAppointmentData] = useState({})
  const [customerData, setCustomerData] = useState({})
  const [showAppointment, setShowAppointment] = useState(false)
  const { isSubmitForm } = useSelector((state) => state.UploadDocumentsReducer)
  const [hastAppointmentPending, setHastAppointmentPending] = useState(false)
  const dispatch = useDispatch()
  const countPage = useRef(0)
  const navigate = useNavigate()

  useEffect(() => {
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])

  useEffect(() => {
    window.onpopstate = (_e) => {
      window.history.pushState(null, '')
    }
    window.history.pushState(null, '')
  }, [])

  useEffect(() => {
    AxiosInterceptor.get('/schedule-appointments/customer')
      .then((response) => {
        const appointmentDate = response?.data?.appointmentDate
        const appointmentTime = response?.data?.appointmentTime
        const branch = response?.data?.branch
        const branchName = response?.data?.branchName
        const customerId = response?.data?.customerId
        // const id = response?.data?.id

        if (
          appointmentDate &&
          appointmentTime &&
          branch &&
          branchName &&
          customerId
        ) {
          setAppointmentData(response?.data)
          setHastAppointmentPending(true)
        } else {
          setShowAppointment(true)
        }
      })
      .catch(() => setShowAppointment(true))
  }, [])

  const checkStatus = async () => {
    const url = '/customer/loan-status'
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.get(url)
      .then((response) => {
        setStatus(response.data.status)

        const firstName = response?.data?.firstName
        const lastName = response?.data?.lastName

        setCustomerData({ firstName, lastName })
      })
      .catch((e) => {
        console.log(e.response)
      })
      .finally(() => {
        dispatch({ type: Actions.LOADING, payload: false })
      })
  }

  const changeStatus = async () => {
    const endpoint = '/update-loan-status/customer/send-to-review'
    dispatch({ type: Actions.LOADING, payload: true })
    await AxiosInterceptor.post(endpoint)
      .then((response) => {
        if (response?.data?.result) navigate('/Congratulations')
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const isStatusValid = (arr, status) => {
    return arr.includes(status)
  }

  // const changeStatus = () => {
  //   AxiosInterceptor.post('/update-loan-status/customer/send-to-review')
  // }

  const getMissingDocs = async () => {
    await AxiosInterceptor.get('/schedule-appointment-missing-docs').then(
      (response) => {
        const ssnImage = response.data.ssnImage
        const proofOfAddressImage = response.data.proofOfAddressImage
        const jobLetterImage = response.data.jobLetterImage
        const loanPurposeDocs = response.data.loanPurposeDocs
        const statusRequest = response?.data?.status
        const monthlyCreditObligations = response?.data?.monthlyCreditObligations

        if (
          ssnImage &&
          proofOfAddressImage &&
          monthlyCreditObligations &&
          jobLetterImage &&
          loanPurposeDocs &&
          isStatusValid([0], statusRequest)
        ) {
          changeStatus()
        }
        if (isStatusValid([3], statusRequest))
          navigate('/NonCustomerPage/ScheduleAppointment/MakeAppointment')
        if (isStatusValid([1], statusRequest)) navigate('/Loan-Status')
        setFiles(response.data)
      }
    )
  }
  const getData = async () => {
    setTimeout(function () {
      return Promise.all([checkStatus(), getMissingDocs()])
    }, 3000)
  }

  useEffect(() => {
    dispatch({ type: Actions.LOADING, payload: true })
    getData().finally(() => {
      async function fetchData() {
        let response = false
        if (
          Files.ssnImage &&
          Files.proofOfAddressImage &&
          Files.jobLetterImage &&
          Files.loanPurposeDocs
        ) {
          await AxiosInterceptor.post(
            '/update-loan-status/customer/send-to-review'
          )
          response = true
        }
        return response
      }
      fetchData().then((value) => {
        // console.log(value);
        if (value == true) {
          navigate('/Congratulations')
        }
      })
      dispatch({ type: Actions.LOADING, payload: false })
    })
  }, [])

  return (
    <>
      {status !== undefined && (
        <Box>
          {hastAppointmentPending && status >= 0 && (
            <AppointmentPending
              customer={customerData}
              appointment={appointmentData}
            />
          )}
          {showAppointment && (
            <>
              {status === 3 ? (
                <MakeAppointment />
              ) : (!Files?.ssnImage ||
                  !Files?.proofOfAddressImage ||
                  !Files?.jobLetterImage ||
                  !Files.monthlyCreditObligations ||
                  !Files?.loanPurposeDocs) &&
                status !== null ? (
                <Box>
                  <MissingDocsUpload Files={Files} />
                  {status !== 0 && status !== 3 && (
                    <ArrowButton
                      link="/NonCustomerPage/LoanStatus"
                      back={!isSubmitForm ? `/NonCustomerPage/AssetsDeclaration` : ''}
                    />
                  )}
                  {status === 0 && (
                    <ArrowButton
                      back="/NonCustomerPage/AssetsDeclaration"
                    />
                  )}
                </Box>
              ) : status !== null ? (
                <Congratulations />
              ) : null}
            </>
          )}
          {(status === 3 || status === null) && (
            <ArrowButton link="/NonCustomerPage/LoanStatus" />
          )}
        </Box>
      )}
    </>
  )
}

export default ScheduleAppointment
