import Actions from "../Types"

const initialState = {
  showModal: false,
  referenceNumber: "",
  token: "",
  statusCode: 0,
}
const returnToInitialState = () => {
  return {
    showModal: false,
  referenceNumber: "",
  token: "",
  statusCode: 0,
  }
}

const OtpEmailReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.OTPEMAILERROR:
      return {
        ...state,
        codeError: payload,
      }
    case Actions.EMAILRESPONSE:
      return {
        ...state,
        statusCode: payload,
      }
    case Actions.EMAILSHOWMODALERROR:
      return {
        ...state,
        showModal: payload,
      }
    case Actions.CLEARSTATE :
        return returnToInitialState()
    default:
      return state
  }
}

export default OtpEmailReducer
