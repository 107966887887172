import Actions from "../Types"
import { Interceptor } from "../../Utils/Interceptor"
import { baseUrl } from "../../baseUrl"
import LocalStorageShedule from "../../Utils/LocalStorageShedule"

export function ScheduleRequestAppointments(data) {
  const schedule_local = LocalStorageShedule.getService()
  // const navigate = useNavigate()
  const { branch, appointmentDate, appointmentTime } = data
  const AxiosInterceptor = Interceptor()
  return async (dispatch, getState) => {
    const url = `/schedule-appointments`
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.MAKESCHEDULE,
          branch: branch,
          date: appointmentDate,
          time: appointmentTime,
        })
        
        schedule_local.setShedule({
          ...response.data,
        })
      })
      .catch((e) => {

      })
  }
}
