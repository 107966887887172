import axios from "axios"
import Actions from "../Types"
import LocalStoreServices from "../../Utils/LocalStoreServices"
import { baseUrl } from "../../baseUrl"

const localStorageServices = LocalStoreServices.getService()

export function otpValidation(data) {
  return async (dispatch, getState) => {
    const url = `${baseUrl}/customers/confirmation/phone`
    dispatch({
      type: Actions.LOADING,
      payload: true,
    })
    await axios
      .post(url, data)
      .then((response) => {
    
        dispatch({
          type: Actions.OTPVALIDATION,
          payload: response.data,
        })
        dispatch({
          type: Actions.OTPSUCCESS,
        })
        dispatch({
          type: Actions.REFERENCESTATUS,
          payload: true,
        })
        localStorageServices.setToken(response.data.token)
        localStorageServices.setReferenceStatus(true)
        localStorageServices.setExpirationDate(response.data.expirationDate)
      })
      .catch((e) => {
        if (
          e.response.data.error.message === "Resend an activation code." || e.response.data.error.message === "Invalid code, please try again." ||  e.response.data.error.message === "Your code has expired."
        ) {
          dispatch({
            type: Actions.OTPERROR,
            payload: true,
          })
        }
      })
      .finally(() => {
        dispatch({
          type: Actions.LOADING,
          payload: false,
        })
      })
  }
}
