import Actions from "../Types";
import { Interceptor } from "../../Utils/Interceptor";

export function UserMessages(data) {
  const AxiosInterceptor = Interceptor();

  return async (dispatch, getState) => {
    const url = "/customer/bbank-important-messages";
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.GETMESSAGES,
          payload: response.data,
        });
      })
      .catch((e) => {
      
      });
  };
}
