import React, { useRef } from 'react'
import { Box, Stack } from '@mui/material'
import ArrowButton from '../ArrowButton/ArrowButton'
import { useStyles } from '../Forms/ScheduleAppointment/ScheduleAppointmentStyle3'
import Calendar from '../../assets/Images/Calendar.png'
import { Typography } from '@material-ui/core'
import Button from '../Button/Button'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import LocalStorageShedule from '../../Utils/LocalStorageShedule'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useNavigate } from "react-router-dom"
const ScheduleThankYou = () => {
  const customer_schedule = JSON.parse(LocalStorageShedule.getShedule())

  const classes = useStyles()
  const inputFile = useRef(null)
  const navigate = useNavigate()
  const { dataSchedule } = useSelector((state) => state.ScheduleRequestReducer)
  // const [firstName, lastName, branch] = ['name', 'lastname', 'branchName']

  const [firstName, lastName, branch] = [
    dataSchedule.firstName,
    dataSchedule.lastName,
    dataSchedule.branch,
  ]


  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          <Typography className={classes.ThankYouPersonalLoanText}>
            <strong>
              Dear {`${firstName || 'name'} ${lastName || 'lastName'}`},
            </strong>{' '}
            <br />
            Your request has been sent to the {` ${branch}`} Branch. Our representative
            will contact you shortly to confirm your appointment date and advice you of the required documents.
            . We will send a reminder 2 hours prior to your scheduled
            appointment. You can proceed to close this application. Talk to you soon! Thank you for giving us the opportunity to serve you.
          </Typography>
         <Stack width="100%" justifyContent="center" alignItems="center" mt={2} mb={2}>
         <Button
                        Title="CLOSE APPLICATION"
                        variant="h7"
                        className={classes.NextButton}
                        BtnVariant="contained"
                        style={{width: "26%"}}
                        onClick={() => navigate('/')}
                        
                      />
         </Stack>
        </Box>
      </Box>
    </Box>
  )
}

export default ScheduleThankYou
