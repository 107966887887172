import React from "react";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import { useStyles } from "./CardStyle";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const FirstCard = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.CardContainer}>
        <Typography variant="subtitle1" className={classes.Text}>
          If you’re a<strong> Belize Bank Customer,</strong> please continue the
          process by logging into your online or mobile banking.
        </Typography>
        <Box className={classes.ButtonWrapper}>
          <a
            href="https://online.belizebank.com/#/auth/login"
            className={classes.Refer}
          >
            <Button
              className={classes.Button}
              Title="LOGIN NOW"
              variant="h7"
              BtnVariant="contained"
            />
          </a>
        </Box>
      </Box>
      <Divider className={classes.CardDivider} />
    </>
  );
};

export default FirstCard;
