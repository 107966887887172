import React from 'react'
import { useDispatch } from 'react-redux'
import { setOTP } from '../store'
import { VerificationBox } from './'

const OTPInput = () => {
  const dispatch = useDispatch()

  return (
    <VerificationBox
      length={5}
      onChange={(e) => dispatch(setOTP(e))}
    />
  )
}

export default OTPInput