import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ErrorContainer from '../Components/ErrorContainer/ErrorContainer'
import Status from '../Components/Forms/LoanStatus/Status'
import MessageSection from '../Components/MessageSection/MessageSection'
import EnrollLaterPage from '../Pages/EnrollLaterPage/EnrolLaterPage'
import EnrollNowPage from '../Pages/EnrollNowPage/EnrollNowPage'
import Home from '../Pages/Home/Home'
import NonCustomerPage from '../Pages/NonCustomerPage/NonCustomerPage'
import { ProductsAndServices } from '../Pages/ProductsServices/ProductsAndServices'
import ThankYouPage from '../Pages/ThankYouPage/ThankYouPage'
import ReferenceNumber from '../Pages/reference-number/'
import VerifyOTP from '../Pages/reference-number/verify-otp/'
import VerifyPhoneNumber from '../Pages/reference-number/verify-phone-number'
import PAGES from './Pages'
import PrivateGuard from './PrivateGuard'
import PrivateReference from './PrivateReference'
import PrivateVerifyOTP from './PrivateVerifyOTP'
// import AgeLimit from '../Pages/AgeLimit'

const Changes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/EnrollNowPage" element={<EnrollNowPage />} />
      <Route path="/EnrollLaterPage" element={<EnrollLaterPage />} />
      <Route path="/ProductsAndServices" element={<ProductsAndServices />} />
      <Route path="/reference-number" element={<ReferenceNumber />} />
      <Route
        path="/reference-number/verify-phone-number"
        element={
          <PrivateReference>
            <VerifyPhoneNumber />
           </PrivateReference>
        }
      />
      <Route
        path="/reference-number/verify-phone-number/otp"
        element={
          <PrivateVerifyOTP>
            <VerifyOTP />
          </PrivateVerifyOTP>
        }
      />
      <Route exact path="/NonCustomerPage" element={<NonCustomerPage />}>
        {PAGES.map(
          ({
            key,
            yourLoanRequestpages,
            letsmeetpages,
            statuspage,
            Component,
            Protected
          }) => {
            return (
              <Route
                key={key}
                exact
                path={`/NonCustomerPage${key}`}
                element={
                  Protected ? (
                    <PrivateGuard>
                      <Component />
                    </PrivateGuard>
                  ) : (
                    <Component />
                  )
                }
              >
                {letsmeetpages &&
                  letsmeetpages.length &&
                  letsmeetpages.map(({ key, Component, Protected }) => {
                    return (
                      <Route
                        key={key}
                        exact
                        path={`/NonCustomerPage/letsMeet${key}`}
                        element={
                          Protected ? (
                            <PrivateGuard>
                              <Component />
                            </PrivateGuard>
                          ) : (
                            <Component />
                          )
                        }
                      />
                    )
                  })}
                {/* {yourLoanRequestpages &&
                  yourLoanRequestpages.length &&
                  yourLoanRequestpages.map(({ key, Component, Protected }) => {
                    return (
                      <Route
                        key={key}
                        exact
                        path={`/NonCustomerPage/yourLoanRequest`}
                        element={
                          Protected ? (
                            <PrivateGuard>
                              <Component />
                            </PrivateGuard>
                          ) : (
                            <Component />
                          )
                        }
                      />
                    )
                  })} */}
              </Route>
            )
          }
        )}
      </Route>
      <Route path="/Congratulations" element={<ThankYouPage />} />
      <Route exact path="/Loan-Status" element={<Status />} />
      <Route exact path="/CustomerChat" element={<MessageSection />} />
      {/* <Route exact path="/error-age-limit" element={<AgeLimit />} /> */}
      <Route path="*" element={<ErrorContainer />} />
    </Routes>
  )
}

export default Changes
