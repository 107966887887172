import { Alert, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Cross from "../../../assets/Images/Cross.png"
import { Box } from "@mui/system"
import React, {useState} from "react"
import { useSelector, useDispatch } from "react-redux"
import Actions from "../../../Store/Types"
import { useStyles } from "./PoppupStyle"
import { Interceptor } from "../../../Utils/Interceptor"
import LoadingButton from "@mui/lab/LoadingButton"

const PopupAlertError = () => {
  const EMAIL_RESPONSE = [
    "Code Valid",
    "Invalid code, please try again.",
    "Validation code expired",
    "A new code was sent, try again.",
    "Resend an activation code."
  ]
  const AxiosInterceptor = Interceptor()
  const classes = useStyles()
  const dispatch = useDispatch()
  const Error = useSelector((state) => state.OtpEmailReducer.showModal)
  const status = useSelector((state) => state.OtpEmailReducer.statusCode)
  const message = EMAIL_RESPONSE[status]
  const [showLoading, setShowLoading] = useState(false)
  const sendData = async () => {
    const url = "/customers/confirmation/resend-code/email"
    return await AxiosInterceptor.post(url)
  }
  const handleReSend = async () => {
    setShowLoading(true)
    const send_data = await sendData()
   
    if(send_data.data.sent) {
      
      dispatch({
        type: Actions.EMAILRESPONSE,
        payload: 3,
      })
      dispatch({
        type: Actions.EMAILSHOWMODALERROR,
        payload: false,
      })
    }
    setShowLoading(false)
  }

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={Error}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>{message}</Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={() =>
              dispatch({
                type: Actions.EMAILSHOWMODALERROR,
                payload: false,
              })
            }
          >
            Re-Enter
          </Button>
          <LoadingButton
           className={classes.AlertBtn} 
           loading={showLoading}
           loadingPosition="end"
           onClick={handleReSend}
           autoFocus>
            Re-Send
          </LoadingButton>
    
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PopupAlertError
