import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  TooltipWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Icon: {
    width: 19.03,
    height: 19.03,
    marginRight: 10,
  },
  Content: {
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 18,
  },
}));
