import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  Wrapper: {
    "& .MuiTypography-root": { //"& .css-ahj2mt-MuiTypography-root"
      fontFamily: theme.fonts.family.tertiary,
      fontSize: 13,
      fontWeight: 600,
      color: theme.colors.primary.Grey,
    },
  },
  Checkbox: {
    "& .MuiSvgIcon-root": {
      borderRadius: 10,
    },
  },
}));
