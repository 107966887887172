import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  Content: {
    textAlign: "center",
    "&.MuiTypography-root": {
      fontSize: 18,
      fontFamily: theme.fonts.family.tertiary,
      fontWeight: 300,
      lineHeight: 1.8,
      color: theme.colors.primary.Grey,
      width: 350,
    },
  },
  StorngText: {
    display: "block",
    color: theme.colors.secondary.Orange,
  },
}));
