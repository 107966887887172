import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/Jwt'
import { validateError } from '../../../Utils/validateError'
import { CustomError } from '../../../Utils/CustomErro'

export const initValidationPhone = createAsyncThunk('initValidationPhone/POST', async (params) => {
  try {
    const response = await UseJwt.initPhoneValidation(params)
    return response.data
  } catch (error) {
    return error
  }
})

export const validateOTPPhone = createAsyncThunk('validateOTPPhone/POST', async (data) => {
  // const { otp, phone } = data
  try {
    const response = await UseJwt.validateOTPPhone(data)
    return response.data
  } catch (error) {
    return error
  }
})

export const resendOTPPhone = createAsyncThunk('resendOTPPhone/POST', async () => {
  try {
    const response = await UseJwt.resendOTPPhone()
    console.log("resendOTPPhone", response)
    return response.data
  } catch (error) {
    return error
  }
})

export const resendOTPEmail = createAsyncThunk('resendOTPPhone/POST', async () => {
  try {
    const response = await UseJwt.resendOTPEmail()
    console.log("resendOTPEmail", response)
    return response.data
  } catch (error) {
    return error
  }
})

export const initValidationEmail = createAsyncThunk('initValidationEmail/POST', async (params) => {
  try {
    const response = await UseJwt.initEmailValidation(params)
    return response.data
  } catch (error) {
    return error
  }
})

export const validateOTPEmail = createAsyncThunk('sendEmailOTP/post', async (data) => {
  try {
    const response = await UseJwt.sendOTPEmail(data)
    return response.data
  } catch (error) {
    return error
  }
})

export const createNonCustomer = createAsyncThunk('createNonCustomer/POST', async (customer) => {
  try {
    const response = await UseJwt.createNonCustomer(customer)
    return response.data
  } catch (error) {
    console.log("Error >>>", error)
    return new CustomError(error.message, 401)
  }
})


const initialState = {
  valid: { phone: false, email: false },
  isModalOpen: { phone: false, email: false },
  values: { phone: '', email: '' },
  step: { phoneValidation: true, emailValidation: false },
  otp: { phone: '', email: '' },
  customer: {  }
}

export const appLetsMeetSlice = createSlice({
  name: 'appLetsMeet',
  initialState,
  reducers: {
    setValid: (state, action) => {
      const { nameState, value } = action.payload
      state.valid[nameState] = value
    },
    setModalOpen: (state, action) => {
      const { modal, value } = action.payload
      state.isModalOpen[modal] = value
    },
    setValues: (state, action) => {
      const { input, value } = action.payload
      state.values[input] = value
    },
    setOTP: (state, action) => {
      console.log("Change >>>", action.payload)
      const { category, value } = action.payload
      state.otp[category] = value
    },
    clearStateVerificationOTP: (state) => {
      state.valid = { phone: false, email: false }
      state.isModalOpen = { phone: false, email: false }
      state.values = { phone: '', email: '' }
      state.step = { phoneValidation: true, emailValidation: false }
      state.otp = { phone: '', email: '' }
      state.customer = {}
    }
  },
  extraReducers: builder =>
    builder
  .addCase(initValidationPhone.fulfilled, (state, action) => {
      state.isModalOpen.phone = true
      state.step.phoneValidation = true
  })
  .addCase(initValidationEmail.fulfilled, (state, action) => {
    const error = validateError(action)
    if (!error.error) {
      state.isModalOpen.email = true
      state.step.emailValidation = true
    }
  })
  .addCase(validateOTPPhone.fulfilled, (state, action) => {
      const { verifiedPhone } = action.payload
      if (verifiedPhone) {
        state.valid.phone = true
        state.step.emailValidation = true
        state.step.phoneValidation = true
        state.isModalOpen.phone = false
      }
  })
  .addCase(validateOTPEmail.fulfilled, (state, action) => {
    const { verifiedEmail } = action.payload
    if (verifiedEmail) {
      state.valid.email = true
      state.step.emailValidation = false
      state.isModalOpen.email = false
    }
  })
  .addCase(createNonCustomer.fulfilled, (state, action) => {
    state.customer = action.payload
  })
})

export const { setValid, setModalOpen, setValues, setOTP, clearStateVerificationOTP } = appLetsMeetSlice.actions

export default appLetsMeetSlice.reducer
