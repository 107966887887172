import React from "react";
import { useStyles } from '../Styles'
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Cross from "../../../assets/Images/Cross.png";
import { Box } from "@mui/system";

import LoadingButton from "@mui/lab/LoadingButton"


const ModalError = ({ error, onCLickReEnter, onClickResSend }) => {
  const classes = useStyles()
  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={error.open}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText}>
          {error.message}
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={onCLickReEnter}
          >
            Re-Enter
          </Button>
          <LoadingButton
           className={classes.AlertBtn}
          //  loading={showLoading}
           loadingPosition="end"
           onClick={onClickResSend}
           autoFocus>
            Re-Send
          </LoadingButton>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default ModalError