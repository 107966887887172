import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  TooltipContainer: {
    backgroundColor: "#E1E1E1",
    width: "50%",
    height: 35,
    filter: "drop-shadow(2px 2px 2px rgba(91, 91, 91, 0.349))",
    display: "flex",
    padding: "0px 10px",
    borderRadius: 10,
    margin: "-17px 10px -19px 126px",
  },
  TooltipWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  Icon: {
    width: 19.03,
    height: 19.03,
    marginRight: 10,
  },
  Content: {
    fontFamily: theme.fonts.family.tertiary,
    fontSize: 13,
    fontWeight: 400,
    lineHeight: 18,
  },
  Tail: {
    width: 0,
    height: 0,
    borderLeft: "10px solid transparent",
    borderRight: "10px solid transparent",
    borderTop: "20px solid #E1E1E1",
    margin: "0.8rem 0rem 0rem 11rem",
    filter: "drop-shadow(2px 5px 2px rgba(91, 91, 91, 0.349))",
    position: "absolute",
    zIndex: 99999,
  },
}));
