import { baseUrl } from '../../baseUrl'



export const endpoints = {
  customer: `${baseUrl}/customer`,
  customers: `${baseUrl}/customers`,
  nonCustomerOTPTemp: `${baseUrl}/non-customer-otp-temp`,
  assets: `${baseUrl}/assets`,
  assetsByCustomer: `${baseUrl}/customer-assets`,


  tokenKeyName: 'access_token',
  phoneToken: 'phone_token',
  emailToken: 'email_token',
  emailOTPToken: 'email_otp_token',

  letMeetToken: 'lets_meet_token',
  tokenType: 'Bearer',
  storageRefreshTokenKeyName: 'refreshToken',
}