const LocalStorageShedule = (function () {
  let _service
  function _getService() {
    if (!_service) {
      _service = this
      return _service
    }
    return _service
  }

  function _setCustomer(values) {
    const data = JSON.stringify(values)
    localStorage.setItem("customer_data", data)
  }

  function _setSchedule(values) {
    const data = JSON.stringify(values)
    localStorage.setItem("customer_schedule", data)
  }

  function _getSchedule() {
    return localStorage.getItem("customer_schedule")
  }

  return {
    getService: _getService,
    setCustomer: _setCustomer,
    setShedule: _setSchedule,
    getShedule: _getSchedule,
  }
})()
export default LocalStorageShedule
