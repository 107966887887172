import React from "react";
import Box from "@mui/material/Box";
import FirstCard from "./FirstCard";
import SecondCard from "./SecondCard";
import ThridCard from "./ThridCard";

const Card = () => {
  return (
    <Box>
      <FirstCard />
      <SecondCard />
      <ThridCard />
    </Box>
  );
};

export default Card;
