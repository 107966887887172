export const validateError = (responseApi) => {

  const error = responseApi?.payload?.response?.data?.error
  const axiosError = responseApi?.payload?.name === 'AxiosError'
  const errorAuth = responseApi?.payload.code === 401
  let result = {
    error: false,
    message: ''
  }

  if (errorAuth) {
    result.error = true
    result.message = 'Session expired, please login again'
    return result
  }

  if (error) {
    result.error = true
    result.message = error.message
    return result
  }

  if (axiosError) {
    result.error = true
    result.message = responseApi?.payload?.message
    return result
  }

  return result
}