import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import LockIcon from '../../assets/Images/Group31.png'
import { ModalForm, PopupError, ReferenceInput } from './components'
import { Errors } from './components/options.enum'
import { errorToastStyle, iconTheme } from './options'
import { setErrorState, verifyReferenceNumber } from './store'

const ReferenceNumberForm = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { referenceNumber, error } = useSelector((state) => state.referenceNumber)

  const onSubmit = () => {
    if (String(referenceNumber).length < 6) return toast.error('Please enter a reference number', {style: errorToastStyle, iconTheme})

    setLoading(true)

    dispatch(verifyReferenceNumber(`BBL${referenceNumber}`))
      .then((response) => {
        if (response?.payload?.role === "Customer") return dispatch(setErrorState({ name: 'referenceNumber', message: Errors.roleInvalid}))
        if (response?.error?.message === "locked") return dispatch(setErrorState({ name: 'referenceNumber', message: Errors.lockedReferenceNumber}))
        if (response?.error) return dispatch(setErrorState({ name: 'referenceNumber', message: response.error.message}))

        localStorage.setItem('referenceNumber_jwt', response.payload.jwt)
        if (response.payload.jwt) navigate('verify-phone-number')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <>
      <Toaster />
      <PopupError
        open={error.name === 'referenceNumber'}
        message={error.message}
        onClick={() => dispatch(setErrorState({ name: null, message: null}))}
        buttonTitle='Accept'
      />
      <ModalForm
        description={'Please enter the reference number'}
        prevText={` BBL -`}
        FormInput={ReferenceInput}
        loading={loading}
        buttonSubmitTitle={'Confirm'}
        onSubmit={onSubmit}
        logo={LockIcon}
      />
    </>
  )
}

export default ReferenceNumberForm
