import * as yup from "yup";

export const validationSchema = yup.object({
  purpose: yup.object("").required("**Please Select purpose"),
  files: yup.array().of(
    yup
      .mixed()
      .test("file-size", "The file cannot exceed 2MB ", function (value) {
        let isValid = true;
        if (value) {
          var filesize = (value?.size / 1024 / 1024).toFixed(4);
          isValid =  filesize <= 2
        } 
        return isValid
      })
  ),
});
