import React from "react";
import { useStyles } from "./EnrollNowPageLinksStyle";
import { Box } from "@mui/system";
import { Stack, Typography } from "@mui/material";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import img from "../../assets/Images/Group1.png";
import img2 from "../../assets/Images/Group2.png";
// import AppleStore from "../../assets/Images/AppleStore.png";
// import GooglePlay from "../../assets/Images/GooglePlay.png";
// import Demo from "./Demo";

const Links = () => {
  const classes = useStyles();

  return (
    <>
      <Stack direction="column" mb={4}>
        <Box className={classes.ProcessHeadingWrapper}>
          <Typography variant="h6" className={classes.ProcessHeading}>
            STEPS TO ENROLL TO BBL MOBILE BANKING
          </Typography>
        </Box>
        <Typography variant="subtitle1" className={classes.processText}>
          The entire process will take you a maximum of 15 minutes
        </Typography>
      </Stack>
      <>
        <img
          src={img}
          style={{ width: "100%", minHeight: "50vh" }}
          className={classes.img1}
        />
        <img
          src={img2}
          style={{ width: "100%", minHeight: "50vh" }}
          className={classes.img2}
        />
      </>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        pt={10}
        className={classes.ButtonWrapper}
      >
        <Link to="/" className={classes.Links}>
          <Button
            className={classes.Button}
            Title="CONTINUE"
            variant="h7"
            BtnVariant="contained"
          />
        </Link>
      </Stack>
    </>
  );
};

export default Links;
