import React from 'react'
import VerificationBox from '../../../Inputs/VerificationInput'
import { useDispatch } from 'react-redux'
import { setOTP } from '../store'

const OTP = ({ length = 6, category = 'phone' }) => {
  const dispatch = useDispatch()
  return (
    <VerificationBox
      length={length}
      onChange={(otp) => dispatch(setOTP({ category, value: otp })) }
    />
  )
}

export default OTP