import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/system";
import { useStyles } from "./NonCustomerPageStyle";
import ProgessBar from "../../Components/ProgressBar/ProgressBar";
import ProcessSteps from "../../Components/ProcessSteps/ProcessSteps";
import { Outlet } from "react-router-dom";
import { useIdleTimer } from 'react-idle-timer'
import IdleTimeOutModal from "./IdleTimeOutModal";
import { useNavigate, useLocation } from "react-router-dom"

const NonCustomerPage = () => {
  const [progrress, setProgrress] = useState(0);
  const [tabs, setTabs] = useState("/NonCustomerPage/letsMeet");
  const classes = useStyles();
  const idleTimer = useRef(null)
  const timeout = 1000 * 300 * 1
  const [showModal, setShowModal] = useState(false)
  const [isTimedOut, setIsTimedOut] = useState(false)
  const [timeVal, setTimeVal] = useState(null); 

  const navigate = useNavigate()

  const onAction = (e) => {
    setIsTimedOut(false)
  }
  const onActive = (e) => {
    setIsTimedOut(false)
  }
  const onIdle = (e) => {
    navigate("/")
  }
  const handleClose = () => {
    setShowModal(false)
  }
  const { getRemainingTime,reset} = useIdleTimer({
    ref : idleTimer,
    element : document ,
    onActive : onActive, 
    onIdle : onIdle ,
    onAction : onAction ,
    debounce : 250,
    timeout : timeout 
  })
  useEffect(() => {
    setInterval(() => {
      setTimeVal(getRemainingTime())
      }, 1000)
  },[])
  useEffect(() => {
     if(timeVal && timeVal <= 60000) 
         setShowModal(true)
      
  },[timeVal])
return (
  <Box className={classes.MainContianer}>
    <IdleTimeOutModal showModal={showModal} getRemainingTime = {getRemainingTime} handleClose = {handleClose}/>
    <ProgessBar progrress={progrress} />
    <ProcessSteps
      setProgrress={(progress) => setProgrress(progress)}
      progress={progrress}
      setTabs={(tabs) => setTabs(tabs)}
      tabs={tabs}
    />
    <Box className={classes.FormContainer}>
      <Outlet />
      
    </Box>
  </Box>
);
};

export default NonCustomerPage;
