import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CallIcon from '../../../assets/Images/phone-icon.png'
import { ModalForm, PhoneNumberInput, PopupError } from '../components'
import { setErrorState, verifyPhoneNumber   } from '../store'
import toast, { Toaster } from 'react-hot-toast'
import { errorToastStyle, iconTheme } from '../options'
import { useNavigate } from 'react-router-dom'
import { Errors } from '../components/options.enum'

const Index = () => {
  const { error, phoneNumber } = useSelector((state) => state.referenceNumber)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [returnInicialProcess, setReturnInicialProcess] = useState(false)

  const isThereError = (response) =>  response === "401" || response.error
  const handleError = (response) => {
      if (response.error.message === "401") {
        dispatch(setErrorState({ name: 'phoneNumber', message: Errors.unauthorized}))
        setTimeout(() => {
          return navigate('/reference-number')
        }, 2500)
        return
      }
      if (response.error.message === "locked") {
        dispatch(setErrorState({ name: 'phoneNumber', message: Errors.lockedPhoneNumber}))
        setReturnInicialProcess(true)
        return
      } else setReturnInicialProcess(false)

      if (response.error) return dispatch(setErrorState({ name: 'phoneNumber', message: response.error.message}))
  }

  const onSubmit = () => {
    if (String(phoneNumber).length < 7) return toast.error('Please enter a phone number', { style: errorToastStyle, iconTheme })
      // return dispatch(setErrorState({ name: 'phoneNumber', message: 'Please enter a phone number'}))
      setLoading(true)

      // el numero de telefono debe lucir de la sigiuente manera 501-601-1111
      const formatPhoneNumber = `501-${ String(phoneNumber).slice(0, 3) }-${ String(phoneNumber).slice(3, 7) }`

      dispatch(verifyPhoneNumber(formatPhoneNumber))
      .then((response) => {
        // console.log('response', response)
        if (isThereError(response)) {
          handleError(response)
          return
        }
        // if (response.error) return dispatch(setErrorState({ name: 'phoneNumber', message: response.error.message}))
        localStorage.setItem('phoneNumber_jwt', response.payload.jwt)
        if(response.payload.jwt) navigate('otp')
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    // localStorage.removeItem('referenceNumber_jwt')
    const token = localStorage.getItem('referenceNumber_jwt')
    if (!token) navigate('reference-number')
  }, [])

  return (
    <>
      <Toaster />
      <PopupError
        open={error.name === 'phoneNumber'}
        message={error.message}
        onClick={() => {
          if(returnInicialProcess){
            navigate('/reference-number')
            dispatch(setErrorState({ name: null, message: null }))
          } else { dispatch(setErrorState({ name: null, message: null })) }
        } }
        buttonTitle='Accept'
      />

      <ModalForm
        description={'Please enter your registered phone number.'}
        prevText={` 501 -`}
        FormInput={PhoneNumberInput}
        loading={loading}
        buttonSubmitTitle={'Confirm'}
        onSubmit={onSubmit}
        logo={CallIcon}
        showBackButton={true}
      />
    </>
  )
}

export default Index
