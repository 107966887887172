import Actions from '../Types'

const initialState = {
  branch: '',
  date: '',
  time: '',
  dataSchedule: {},
}
const returnToInitialState = () => {
  return {
    branch: '',
  date: '',
  time: '',
  dataSchedule: {},
  }
}
const ScheduleRequestReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.ScheduleRequest:
      return {
        ...state,
      }

    case Actions.SCHEDULELOANDREQUEST:
      return {
        ...state,
        dataSchedule: { ...payload },
      }
    case Actions.CLEARSTATE :
        return returnToInitialState()
    default:
      return state
  }
}

export default ScheduleRequestReducer
