import React from "react";
import EnrollLaterModal from "./EnrollLaterModal";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useStyles } from "./EnrollLaterContainerStyle";
import CardHeader from "@mui/material/CardHeader";
import Stack from "@mui/material/Stack";
import Button from "../Button/Button";
import ButtonWhatsApp from "../Button/ButtonWhatsApp"
import clsx from "clsx";
import EnrollLaterLogo from "../../assets/EnrollLaterLogo.png";
import HelpTooltip from "../Tooltips/HelpTooltip/HelpTooltip";
import { Link } from "react-router-dom";

const EnrollLaterContainer = ({ modal }) => {
  const classes = useStyles();
  return (
    <EnrollLaterModal ModalOne={modal}>
      <Box className={classes.MainContainer}>
        <CardHeader className={classes.ModalHeader}> </CardHeader>
        <Box className={classes.CardContainer}>
          <Box className={classes.IconWrapper}>
            <img
              src={EnrollLaterLogo}
              alt=""
              className={classes.EnrollLaterLogo}
            />
          </Box>
          <Box className={classes.TextBox}>
            <Box className={classes.Wrapper}>
              <Typography variant="subtitle1" className={classes.Text}>
                <strong> As a Belize Bank customer </strong> you can participate
                in our
                <strong> loyalty program</strong> and enjoy lower rates. We
                recommend that you{" "}
                <strong>download the Belize Bank mobile banking app </strong>
                in order to enroll and take advantage of all the benefits we
                have to offer.
              </Typography>
              <Stack className={classes.stackButton} direction="row">
                <Link to="/EnrollNowPage" className={classes.Links}>
                  <Button
                    Title="ENROLL NOW"
                    variant="subtitle1"
                    className={classes.Button}
                    BtnVariant="contained"
                  />
                </Link>
                <ButtonWhatsApp
                  Tooltiptitle={<HelpTooltip />}
                  Title="NEED ASSISTANCE"
                  variant="subtitle1"
                  className={clsx(classes.Button, classes.HelpBtn)}
                  BtnVariant="contained"
                  backgroundColor="white"
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>
    </EnrollLaterModal>
  );
};

export default EnrollLaterContainer;
