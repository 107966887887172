import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ErrorContainer: {
    width: "100%",
    height: "100vh",
  },
  ErrorImage: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));
