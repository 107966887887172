import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MainContainer: {
    width: "1033px",
    padding: "10px",
    margin: "6rem auto 2rem auto",
    ["@media (max-width: 1100px)"]: {
      width: "auto",
    },
  },
}));
