import React from 'react'
import { Navigate } from 'react-router-dom'
// import LocalStorageService from "../Utils/LocalStoreServices"
import jwt_decode from 'jwt-decode'
import moment from 'moment'

const PrivateVerifyOTP = ({ children }) => {
  let AccessToken = localStorage.getItem('phoneNumber_jwt')
  // const status = LocalStorageService.getReferenceStatus()
  // if (Number(status) === 1 || Number(status) === 2) {
  //   LocalStorageService.clearToken()
  //   AccessToken = ""
  // }
  let isvalidToken = false
  if (AccessToken) {
    try {
      const decode = jwt_decode(AccessToken)
      const date = moment(new Date()).format('X')
      isvalidToken = decode.exp - 0 >= date
    } catch (error) {
      isvalidToken = false
    }
  } else {
    isvalidToken = false
  }
  return (
    <>
      {isvalidToken ? <>{children} </> : <Navigate to={'/reference-number'} />}
    </>
  )
}
export default PrivateVerifyOTP
