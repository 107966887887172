import Actions from "../Types";

const initialState = {
  radioChecked: null,
};

const RadioChecked = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.RADIOCHECKED:
      return {
        ...state,
        radioChecked: payload,
      };

    default:
      return state;
  }
};

export default RadioChecked;
