export const STATUS = {
  DECLINED: 4,
  FINALIZED: 10,
}

export const Errors = {
  unauthorized: 'Session expired. Please try again.',
  lockedOTP: 'One Time Password (OTP) has been incorrectly entered 3 times. Our Customer Support will contact you to continue the process',
  lockedPhoneNumber: 'Phone number has been incorrectly entered 3 times. Our Customer Support will contact you to continue the process',
  lockedReferenceNumber: 'The limit of failed attempts has been reached. Your access has been blocked.',
  roleInvalid: 'Invalid reference number, please try again',
}