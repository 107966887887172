import { Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useStyles } from "./TermsAndConditionsStyle";
import Recomend from "../../assets/Images/Recomend.png";
import { ContentData } from "./ContentTextData";
import { List } from "./ListData";
import Button from "../Button/Button";
import { Link } from "react-router-dom";

const TermsAndConditions = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.TermsAndConditionsContainer}>
      <Box className={classes.TermsAndConditionsHeader}>
        <Typography>TERMS AND CONDITIONS</Typography>
      </Box>
      <Stack direction="column" justifyContent="center" alignItems="center">
        <img className={classes.RecomendImage} src={Recomend} alt="" />

        <Stack direction="column" className={classes.Content} spacing={4}>
          <Typography>{ContentData[0].text}</Typography>
          <Typography>{ContentData[1].text}</Typography>
          <Typography>{ContentData[2].text} <b>IF </b>{ContentData[3].text}</Typography>
          {/* {ContentData.map((data) => (
            <Typography>{data.text}</Typography>
          ))} */}
        </Stack>

        <Stack className={classes.BenefitList}>
          <Box className={classes.BenefitListHeader}>
            <Typography>
              Debt Cancellation Scheme benefits:
            </Typography>
          </Box>
          <Stack
            direction="column"
            mt={4}
            spacing={4}
            className={classes.Lists}
          >
            {List.map((items) => (
              <Stack
                direction="row"
                spacing={1}
                alignItems="start"
                className={classes.ListItem}
              >
                <img
                  className={classes.ListItemImage}
                  src={items.image}
                  alt=""
                />
                <Typography>{items.text}</Typography>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Link to="/NonCustomerPage/yourLoanRequest" className={classes.Links}>
          <Button
            className={classes.BackBtn}
            Title="BACK"
            onClick={props.BackPage}
          />
        </Link>
      </Stack>
    </Box>
  );
};

export default TermsAndConditions;
