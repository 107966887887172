import Group2 from "../../assets/NonCustomerImages/Group 2.png";
import Group3 from "../../assets/NonCustomerImages/Group 3.png";
import Group4 from "../../assets/NonCustomerImages/Group 4.png";
import Group5 from "../../assets/NonCustomerImages/Group 5.png";
import Group6 from "../../assets/NonCustomerImages/Group 6.png";
import Group7 from "../../assets/NonCustomerImages/Group 7.png";
import Assets from "../../assets/NonCustomerImages/assets-circle-modified.png";
import Group8 from "../../assets/NonCustomerImages/Group 8.png";
import Group9 from "../../assets/NonCustomerImages/Group 9.png";

export const ProcessArray = [
  {
    id: 1,
    Title: "LET’S MEET",
    Icon: Group2,
    Link: "/NonCustomerPage/letsMeet",
  },
  {
    id: 2,
    Title: "YOUR LOAN REQUEST",
    Icon: Group3,
    Link: "/NonCustomerPage/yourLoanRequest",
  },
  {
    id: 3,
    Title: "MONTHLY INCOME",
    Icon: Group4,
    Link: "/NonCustomerPage/monthlyIncome",
  },
  {
    id: 4,
    Title: "MONTHLY CREDIT OBLIGATIONS",
    Icon: Group5,
    Link: "/NonCustomerPage/MonthlyCreditObligations",
  },
  {
    id: 5,
    Title: "LOAN PURPOSE",
    Icon: Group6,
    Link: "/NonCustomerPage/LoanPurpose",
  },
  {
    id: 6,
    Title: "PROOF OF INCOME",
    Icon: Group7,
    Link: "/NonCustomerPage/ProofOfIncome",
  },
  {
    id: 7,
    Title: "ASSETS DECLARATION",
    Icon: Assets,
    Link: "/NonCustomerPage/AssetsDeclaration",
  },
  {
    id: 8,
    Title: "DOCUMENTATION REQUIRED",
    Icon: Group8,
    Link: "/NonCustomerPage/ScheduleAppointment",
  },
  {
    id: 9,
    Title: "LOAN STATUS",
    Icon: Group9,
    Link: "/NonCustomerPage/LoanStatus",
  },
];
