import React from "react"
import Box from "@mui/material/Box"
import { Typography } from "@mui/material"
import { useStyles } from "./CardStyle"
import Button from "../Button/Button"
import { Link } from "react-router-dom"

const ThridCard = () => {
  const classes = useStyles()
  return (
    <Box className={classes.CardContainer}>
      <Typography variant="subtitle1" className={classes.ThirdCardText}>
        If you're <strong> NOT a Belize Bank Customer,</strong> you are welcome
        to join by starting the online loan process <strong>NOW.</strong>
      </Typography>
      <Box className={classes.SecondButtonWrapper}>
        <Link to="/reference-number" className={classes.Links}>
          <Button
            className={classes.ThirdButton}
            Title="I HAVE A REFERENCE NUMBER"
            variant="h7"
            BtnVariant="contained"
          />
        </Link>
        <Link to="/NonCustomerPage/letsMeet" className={classes.Links}>
          <Button
            className={classes.ThirdButton}
            Title="CONTINUE AS A NON-CUSTOMER"
            variant="h7"
            BtnVariant="contained"
          />
        </Link>
      </Box>
    </Box>
  )
}

export default ThridCard
