export const LOANS_SUBTYPES = {
  unsecure: {
    customer: 2,
    nonCustomer: 1
  },
  secure: {
    cash: 4,
    land: 5,
    vehicle: 6
  }
}