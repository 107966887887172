import moment from "moment"
import LocalStorageService from "./LocalStoreServices"

const IsUservalid = () => {
  const token = LocalStorageService.getAccessToken() ?? false
  token && isTokenExpirationDateValid()
}

const isTokenExpirationDateValid = () => {
  const expirationDate = moment(LocalStorageService.getExpirationDate())
  // console.alert(expirationDate)
  if (!expirationDate ?? false) clearUserLocal()
  let today = moment(Date()).add(0, "hours")
  let diffe = expirationDate.diff(today, "hours") >= 0
  if (!diffe) clearUserLocal()
}

const clearUserLocal = () => {
  LocalStorageService.clearToken()
  console.log("Token is not valid, LocalStorageService.clearToken()")
}

export default IsUservalid
