import React from "react";
import Box from "@mui/material/Box";
import { useStyles } from "./LaterTooltipStyle";
import { Typography } from "@mui/material";

const LaterTooltip = () => {
  const classes = useStyles();
  return (
    <Box className={classes.TooltipContainer}>
      <Box className={classes.ContentContainer}>
        <Typography variant="subtitle1" className={classes.Content}>
          All documents will have to be submitted before the final submission of
          the online loan application.
        </Typography>
      </Box>
    </Box>
  );
};

export default LaterTooltip;
