import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  LoanPurposeContainer: {
    width: "100%",
  },

  LoanPurposeHeading: {
    "&.MuiTypography-root": {
      fontSize: "15px",
      fontWeight: "500",
      lineHeight: "20.49px",
      letterSpacing: "0.05em",
      color: "#005FAA",
    },
  },

  UploadFiles: {
    "&.MuiButtonBase-root": {
      justifyContent: "space-between",
      height: "43px",
      border: " 1px solid #DADADA",
      color: "#B2B2B2",
      fontSize: "13px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.tertiary,
      "@media (max-width: 993px)": {
        fontSize: "0.6rem",
        fontWeight: "600",
        height: 'auto !important'
      }
    },
  },

  PlusIcon: {
    "&.MuiSvgIcon-root": {
      color: "#F07F3C",
      width: "21px",
      height: "21px",
    },
  },
  RemoveIcon: {
    "&.MuiSvgIcon-root": {
      width: "21px",
      height: "21px",
      color: "#F07F3C",
    },
  },
  UploadBtn: {
    width: "165px",
    height: "39.06px",
    "&.MuiButton-root": {
      background: "#005FAA",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      lineHeight: "15.51px",
      fontWeight: "600",
      letterSpacing: "0.2em",
      color: "#ffff",
      fontFamily: theme.fonts.family.primary,
    },
  },
  LaterButton: {
    width: "365.23px",
    height: 42,
    "&.MuiButtonBase-root": {
      width: "80%",
      marginTop: 23,
      // backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "White",
        fontWeight: 500,
        letterSpacing: "0.1em",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        marginTop: "0px",
      },
    },
  },
  LaterButtonTwo: {
    width: "365px",
    height: 42,
    "&.MuiButtonBase-root": {
      width: "80%",
      marginTop: "23px !important",
      // backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "&.MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.secondary,
        color: "White",
        fontWeight: 500,
        letterSpacing: "0.1em",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
  },
  fileText: {
    width: "460px",
    fontSize: "13px",
    fontWeight: "500",
    lineHeight: "17.75px",
    fontFamily: theme.fonts.family.secondary,
    color: "#706F6F",
    "@media (max-width: 1200px)": {
      width: "100%",
    },
  }, 
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginBottom:15
  },
}));
