import { useDispatch } from 'react-redux'
import { VerificationBox } from './'
import { setPhoneNumber } from '../store'

const PhoneNumberInput = () => {
  const dispatch = useDispatch()

  return (
    <VerificationBox
      length={7}
      onChange={(e) => dispatch(setPhoneNumber(e))}
    />
  )
}

export default PhoneNumberInput