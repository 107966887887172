export const ContentData = [
  {
    id: "1",
    text: "Debt Cancellation Scheme: I hereby consent to you establishing a policy under the Belize Bank Personal Demand Loan Debt Cancellation Scheme, with loss payable, if any, to The Belize Bank Limited (the “Bank”). The Debt Cancellation Scheme coverage is to be effective as from the date on which the loan is advanced and on such terms and conditions as may be agreed between I and the Bank, but coverage is not to exceed the principal amount of the Personal Demand Loan.",
  },
  {
    id: "2",
    text: "I certify that I am not undergoing any medical treatment and within the past twelve (12) months, I have not been hospitalized. I further confirm that I am not suffering from any serious ailment.",
  },

  {
    id: "3",
    text: `I acknowledge, by signing this consent and declaration that the Bank is not liable to payout my debt upon my death if I knowingly misinformed the Bank. I further acknowledge that the Bank is not liable to pay off my debt `,
  },
  {
    id: '4',
    text: ' my loan is in arrears for ninety (90) days or more.'
  }
];
