import ReferenceNumberForm from './FormReferenceNumber'

const Index = () => {

  return (
    <>
      <ReferenceNumberForm />
    </>
  )
}

export default Index