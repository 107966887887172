import Actions from "../Types";

const initialState = {
  BankMessages: [],
};
const returnToInitialState = () => {
  return {
    BankMessages: []
  }
}
const GetMessagesReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.GETMESSAGES:
      return {
        ...state,
        BankMessages: payload.reverse(),
      };
    case Actions.CLEARSTATE :
        return returnToInitialState()
    default:
      return state;
  }
};

export default GetMessagesReducer;
