import React, { Fragment, useRef, useState, useEffect } from "react"
import { Stack } from "@mui/system"
import { useStyles } from "./LoanPurposeStyle"
import { Box, Typography } from "@material-ui/core"
import Select from "../../Inputs/Select"
import Button from "./Button"
import AddCircleIcon from "@mui/icons-material/AddCircle"
import AttachFileIcon from "@mui/icons-material/AttachFile"
import LaterTooltip from "../../Tooltips/LaterTooltip/LaterTooltip"
import ArrowButton from "../../ArrowButton/ArrowButton"
import ClearIcon from "@mui/icons-material/Clear"
import LoanPurposeOtp from "../../OtpPopup/LoanPurposeOtp"
import { baseUrl } from "../../../baseUrl"
import {
  useFormik,
  ErrorMessage,
  FieldArray,
  Field,
  FormikProvider,
} from "formik"
import { Interceptor } from "../../../Utils/Interceptor"
import { validationSchema } from "./validation"
import clsx from "clsx"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { Link, useNavigate } from "react-router-dom"
import Actions from "../../../Store/Types"
import { useDispatch } from "react-redux"
import PoppUpEmailConfirmation from "./PoppUpEmailConfirmation"
import PopupAlertError from "./PopupAlertError"

const LoanPurpose = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const filesRef = useRef([])
  const Navigate = useNavigate()
  const [isValid, setIsValid] = useState(false)
  const [confirmEmail, setconfirmEmail] = useState(true)
  const [arrayFieldAmount, setArrayFieldAmount] = useState(0)
  const AxiosInterceptor = Interceptor()
  const countPage = useRef(0)
  const convertToUpload = (theBlob, filename) => {
    if (theBlob != null) {
      var arr = theBlob.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return ""
    }
  };
  const getLoanPurposeTypes = async (purposeId) => {
    if(purposeId != 0){
      const response = await AxiosInterceptor.get(
        `/loan-purpose-types/${purposeId}?filter={ "order": "position"}`
      )
      return response.data;
    }else{
      return ""
    }
  };
  const getData = async () => {
    await AxiosInterceptor.get("/loan-purposes").then(async (response) => {
      if (response.data) {
        let data = response.data
        
        let files = []
        if (data?.statementFile1)
          files.push(convertToUpload(data.statementFile1,data.statementFileName1))
        if (data?.statementFile2)
          files.push(convertToUpload(data.statementFile2 ,data.statementFileName2))
        if (data?.statementFile3)
          files.push(convertToUpload(data.statementFile3 ,data.statementFileName3))
        
        const formData = {
          files: files.length > 0 ? files : [""] ,
          purpose: data?.idCategory ? await getLoanPurposeTypes(data.idCategory) : '',
          customerId: data?.customerId ? data.customerId : '',
          id: data?.id ? data.id : ''
          
        }
        formik.setValues(formData)
       
      
      }
     
    })
    return
  }
  const sendData = async () => {
    const url = "/customers/confirmation/resend-code/email"
    return await AxiosInterceptor.post(url)
  }
  const handleReSend = async () => {
    const send_data = await sendData()
    if(send_data.data.sent) {
      dispatch({
        type: Actions.EMAILRESPONSE,
        payload: 3,
      })
      dispatch({
        type: Actions.EMAILSHOWMODALERROR,
        payload: false,
      })
    }
 
  }

  const isEmailSent = async () => {
    try {
      await AxiosInterceptor.post("/customers/send-email-code").then(async (response) => {
        setconfirmEmail(false)
       // handleReSend()
      })
   } catch(e) {
     setconfirmEmail(true)
   } 
   return 
  
  }

  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null,'')
    }
    window.history.pushState(null,'')
  }, [])
  const onPageLoad = async () => {
    return Promise.all([
      isEmailSent(),
      getData()
    ])
  }
  useEffect(() => {
  
    dispatch({ type: Actions.LOADING, payload: true })
    onPageLoad().finally(() => {
      dispatch({ type: Actions.LOADING, payload: false })
    })
    if (countPage.current === 0) {
      countPage.current = 1
      window.scroll(0, 0)
      return
    }
  }, [])
  const formik = useFormik({
    initialValues: {files: [""],purpose:'',id: ""},
    enableReintialize: true,
    onSubmit: (values) => {
      handleSubmit(values)
    },
    validationSchema: validationSchema,
  })
  const { values, setFieldValue } = formik
  const onButtonClick = (index, e) => {
    filesRef.current[index].click()
  }
  const convertBase64 = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
          resolve({file: fileReader.result, fileName: file.name})
        }

        fileReader.onerror = (error) => {
          reject(error)
        }
      })
    } else {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }
  }
  const handleSubmit = async () => {
    setIsValid(true)
    const formArray = await Promise.all(
      formik.values.files.map(async (file, index) => {
        let data = await convertBase64(file)
        return data
      })
    )
    if(formik.values.id === ""){
      let formData = {
        idCategory: formik.values.purpose.id,
        statementFile1: formArray[0] ? formArray[0].file : null,
        statementFile2: formArray[1] ? formArray[1].file : null,
        statementFile3: formArray[2] ? formArray[2].file : null,
        statementFileName1 : formArray[0] ? formArray[0].fileName : null,
        statementFileName2 : formArray[1] ? formArray[1].fileName : null,
        statementFileName3 : formArray[2] ? formArray[2].fileName : null
      }
      const AxiosInterceptor = Interceptor()
  
      await AxiosInterceptor.post("/loan-purposes", formData)
        .then((response) => {
         
         // return Navigate("/NonCustomerPage/ProofOfIncome")
        })
        .catch((e) => {
        })
    }else{
      let formData = {
        id:formik.values.id,
        customerId: formik.values.customerId,
        idCategory: formik.values.purpose.id,
        statementFile1: formArray[0] ? formArray[0].file : null,
        statementFile2: formArray[1] ? formArray[1].file : null,
        statementFile3: formArray[2] ? formArray[2].file : null,
        statementFileName1 : formArray[0] ? formArray[0].fileName : null,
        statementFileName2 : formArray[1] ? formArray[1].fileName : null,
        statementFileName3 : formArray[2] ? formArray[2].fileName : null
      }
      const AxiosInterceptor = Interceptor()
  
      await AxiosInterceptor.put(`/loan-purposes/${formik.values.id}`, formData)
        .then((response) => {
          return Navigate("/NonCustomerPage/ProofOfIncome")
        })
        .catch((e) => {
        })
    }
  }
  const handleClicNext = () => {
    formik.submitForm()
  }
  useEffect(() => {
    isValid && Navigate("/NonCustomerPage/ProofOfIncome")
  },[isValid])
  
  return (
    <Box className={classes.LoanPurposeContainer}>
      <PopupAlertError />
      {!confirmEmail && <PoppUpEmailConfirmation setconfirmEmail={setconfirmEmail} />}
      <Typography className={classes.LoanPurposeHeading}>
        A maximum of one loan purpose can be selected. If your loan will cover multiple purposes, please select the purpose for which the majority of funds will be used.
      </Typography>
      <FormikProvider value={formik}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
          mt={1.5}
          mb={36}
        >
          <Stack width={{ xs: "100%", sm: "50%" }}>
            <Field name="purpose">
              {({ field, form, meta }) => (
                <>
                  <Select
                    {...field}
                    // Label="purpose"
                    placeholder="SELECT A LOAN PURPOSE"
                    ApiUrl= {`${baseUrl}/loan-purpose-types?filter={ "order": "position"}`}
                    error={meta.touched && meta.error}
                    onBlur={() => {
                      formik.setFieldTouched(field.name)
                    }}
                    value={meta.value}
                    onChange={(e, value) => {
                      value && form.setFieldValue(field.name, value);
                    }}
                  />
                </>
              )}
            </Field>
          </Stack>

          <Stack width={{ xs: "100%", sm: "50%" }} mt={4}>
            <FieldArray
              name="files"
              render={(arrayHelpers) => (
                <Fragment>
                  {values.files.map((field, index) => (
                    <Stack key={index} mb={3}>
                      <Field name={`files.${index}`}>
                        {({ field, form, meta }) => (
                          <>
                            <Stack
                              direction="row"
                              width={{ xs: "100%", sm: "100%" }}
                              alignItems="center"
                            >
                              <Button
                                Title="Please upload your supporting documents i.e. quotations, invoices, bank statements"
                                variant="h7"
                                className={classes.UploadFiles}
                                endIcon={<AttachFileIcon />}
                                onClick={(e) => {
                                  onButtonClick(index, e)
                                  formik.setFieldTouched(field.name)
                                }}
                                style={{ width: "100%" }}
                              />

                              <input
                                {...field}
                                type="file"
                                accept="application/pdf,image/*"
                                onChange={(event) => {
                                  if (event.currentTarget.files) {
                                    setFieldValue(
                                      field.name,
                                      event.currentTarget.files[0]
                                    )
                                  }
                                  //formik.setFieldTouched(field.name);
                                }}
                                value=""
                                style={{ display: "none", width: "90%" }}
                                ref={(el) => (filesRef.current[index] = el)}
                              />
                              {index == 0 ? (
                                <AddCircleIcon
                                  className={classes.PlusIcon}
                                  onClick={() => {
                                    if (arrayFieldAmount < 2) {
                                      arrayHelpers.push("")
                                      setArrayFieldAmount(
                                        (prevData) => prevData + 1
                                      )
                                    }
                                  }}
                                />
                              ) : (
                                <HighlightOffIcon
                                  className={classes.RemoveIcon}
                                  onClick={() => {
                                    arrayHelpers.remove(index)
                                    setArrayFieldAmount(
                                      (prevData) => prevData - 1
                                    )
                                  }}
                                />
                              )}
                            </Stack>
                            <Typography className={classes.fileText}>
                              Only pdf documents are permitted with a maximum of
                              2MB
                            </Typography>
                            {meta.error ? (
                              <p
                                className={classes.fileText}
                                style={{ color: "red", marginTop: 5 }}
                              >
                                {meta.error}
                              </p>
                            ) : (
                              meta?.value?.name && (
                                <p className={classes.uploadFileName}>
                                  {`${String(meta.value.name).substring(0, 20)} ...`}
                                  <span>
                                    <ClearIcon
                                      className={classes.deleteFileIcon}
                                      onClick={() => {
                                        formik.setFieldValue(field.name, null)
                                      }}
                                    />
                                  </span>
                                </p>
                              )
                            )}
                          </>
                        )}
                      </Field>
                    </Stack>
                  ))}
                </Fragment>
              )}
            />
          </Stack>
        </Stack>
      </FormikProvider>
      <LoanPurposeOtp />
      <ArrowButton
        link={isValid && "/NonCustomerPage/ProofOfIncome"}
        back="/NonCustomerPage/MonthlyCreditObligations"
        onClick={handleClicNext}
      />
    </Box>
  )
}

export default LoanPurpose
