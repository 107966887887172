import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  LinksMainContainer: {
    marginTop: "3rem",
  },
  img1: {
    width: "100%",
    minHeight: "50vh",
    ["@media (max-width: 800px)"]: {
      display: "none",
    },
  },

  img2: {
    width: "100%",
    minHeight: "50vh",
    display: "none",
    ["@media (max-width: 800px)"]: {
      display: "block",
    },
  },

  ProcessHeadingWrapper: {
    height: 43,
    backgroundColor: theme.colors.primary.Darkblue,
    borderRadius: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    filter: "drop-shadow(2.835px 2.835px 2.834px rgba(91, 91, 91, 0.349))",
  },
  ProcessHeading: {
    "&.MuiTypography-root": {
      fontFamily: theme.fonts.family.quaternary,
      fontSize: 19,
      fontWeight: 600,
      letterSpacing: "0.2em",
      color: theme.colors.secondary.White,
      textAlign: "center",
      ["@media (max-width: 800px)"]: {
        fontSize: "15px",
        fontWeight: "400",
      },
      ["@media (max-width: 500px)"]: {
        fontSize: "12px",
      },
      ["@media (max-width: 400px)"]: {
        fontSize: "10px",
      },
    },
  },
  processText: {
    textAlign: "center",
    "&.MuiTypography-root": {
      margin: "12px 0px",
      fontFamily: theme.fonts.family.primary,
      fontSize: 19,
      fontWeight: 400,
      letterSpacing: "0.2em",
      color: theme.colors.secondary.Orange,
      ["@media (max-width: 800px)"]: {
        fontSize: "17px",
      },
      ["@media (max-width: 500px)"]: {
        fontSize: "15px",
      },
    },
  },
  ButtonWrapper: {
    ["@media (max-width: 970px)"]: {
      paddingTop: "0px",
    },
    ["@media (max-width: 470px)"]: {
      paddingTop: "0px",
    },
  },
  Button: {
    width: 176,
    height: 39,
    "&.MuiButtonBase-root": {
      marginBottom: 30,
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .MuiTypography-root": {
        fontSize: 13,
        fontFamily: theme.fonts.family.primary,
        fontWeight: 600,
        letterSpacing: "0.2em",
      },
    },
  },
  Links: {
    textDecoration: "none",
  },
}));
