import { Alert, Typography } from "@mui/material"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import Button from "@mui/material/Button"
import Cross from "../../assets/Images/Cross.png"
import { Box } from "@mui/system"
import React from "react"
import { useStyles } from "./ReferencePopUpStyle.js"

const PopupError = ({open, message, onClick, buttonTitle = "Re-Enter"}) => {
  const classes = useStyles()

  return (
    <Dialog
      className={classes.AlertBoxContainer}
      open={open}
      aria-labelledby="draggable-dialog-title"
    >
      <Box className={classes.AlertBoxHeader}></Box>
      <Box className={classes.AlertMainSection}>
        <Box className={classes.HeaderLogoContainer}>
          <Box className={classes.HeaderLogoImage}>
            <img className={classes.CrossImage} src={Cross} alt="Cross" />
          </Box>
        </Box>
        <Typography className={classes.AlertText} align='center'>
          {message}
        </Typography>
        <DialogActions>
          <Button
            className={classes.AlertBtn}
            autoFocus
            onClick={onClick}
          >
            {buttonTitle}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}

export default PopupError
