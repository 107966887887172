import React from 'react'
import { Box } from '@mui/material'
import { useStyles } from './ScheduleAppointmentStyle3'
import Approved from '../../../assets/Images/Approved.png'
import { Typography } from '@material-ui/core'

const ScheduleApproved = () => {
  const classes = useStyles()

  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={classes.ApprovedPoppup}>
          <Box className={classes.ApprovedPoppupImage}>
            <img className={classes.ApprovedImage} src={Approved} alt="" />
          </Box>
          <Typography className={classes.ApprovedText}>
            <strong>Your loan has been approved. </strong>
            Please download the salary assignment letter and request your
            employer to complete it. Once completed, login, upload the document
            and schedule your appointment. Thank you.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ScheduleApproved
