import React from 'react'
import { ModalForm } from './components'
import OTP from './components/OTPForm'
import { Stack } from '@mui/system'
import { Button, Box, Modal } from '@mui/material'
import { useStyles } from './LetsMeetFormStyle'
import Input from '../../Inputs/Input'
import clsx from 'clsx'
import { validationsSchemaEmail } from './validations'
import { useFormik } from 'formik'
import SmsText from '../../../assets/Images/sms-text.png'
import { useSelector } from 'react-redux'
import { CheckCircle } from '@mui/icons-material'
import "./styles.css"


const EmailOTP = () => <OTP category='email' />

const MailValidation = ({
  isVerified = false,
  pending = { email: false, otpEmail: false },
  openVerification = false,
  handleChange = (email) => console.log(email),
  initValidation = () => console.log('Init Validation'),
  onSubmitOTPVerification = () => console.log('Submit OTP'),
  onClose = () => console.log('Close Modal')
}) => {
  const classes = useStyles()

  const { step } = useSelector(state => state.letsMeet)

  const disabled = isVerified || pending.email || !step.emailValidation

  const handleSubmit = (values) => {
    initValidation(values.email)
    handleChange(values.email)
  }

  const formik = useFormik({
    initialValues: { email: "", confirmEmailAddress: '' },
    validationSchema: validationsSchemaEmail,
    onSubmit: handleSubmit
  })


  return (
    <Stack direction="row" spacing={2} mt={3} alignItems="center">
      <form onSubmit={formik.handleSubmit} className='responsive-form'style={{ display: 'flex', alignItems: 'baseline', width: '100%', gap: 16 }}>
        <Box sx={{ flexGrow: 5 }}>
          <Input
            Lable="EMAIL ADDRESS*"
            placeholder="Enter email address"
            name="email"
            autoComplete="off"
            disabled={disabled}
            error={formik.touched.email && formik.errors.email}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={clsx({ [classes.InputDisabled]: disabled })}
          />
        </Box>
        <Box sx={{ flexGrow: 4 }}>
          <Input
            placeholder="Confirm email address"
            name="confirmEmailAddress"
            autoComplete="off"
            error={ formik.touched.confirmEmailAddress && formik.errors.confirmEmailAddress}
            disabled={disabled}
            value={formik.values.confirmEmailAddress}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={clsx({ [classes.InputDisabled]: disabled })}
          />
        </Box>
        <Box sx={{ ml: 2 }} className='btn-confirm'>
        {!isVerified && (
          <Button variant="contained" color="primary" disabled={disabled} type='submit' >
            Verify
          </Button>
        )}
        {isVerified && (
            <Button
              variant="outlined"
              color="inherit"
              disabled
          >
            <span className='text-button-mobile'> Confirmation completed </span> {' '}
            <CheckCircle color="success" />
          </Button>
          )}
        </Box>
      </form>

      {openVerification && (
        <Modal
          open={openVerification}
          onClose={onClose}
          disableAutoFocus={false}
        >
          <ModalForm
            description={`Please enter the 6-digit code sent to your email address`}
            prevText={''}
            FormInput={EmailOTP}
            expirationTime='15 minutes'
            loading={pending.otpEmail}
            buttonSubmitTitle={'Confirm'}
            onSubmit={onSubmitOTPVerification}
            logo={SmsText}
            // showBackButton={true}
          />
        </Modal>
      )}
    </Stack>
  )
}

export default MailValidation
