import { Box, Stack } from '@mui/material'
import { AssetsGroup, SelectInput } from './components'
import React from 'react'
import { Field, Form, FormikProvider, useFormik } from 'formik'
import ArrowButton from '../../ArrowButton/ArrowButton'
import { useStyles } from './components/Style'
import { useDispatch, useSelector } from 'react-redux'
import {
  addInputInState,
  deleteAssetByCustomer,
  deleteInputInState,
  getAssets,
  getAssetsByCustomer,
  saveAssetByCustomer,
  setTempAssets,
  updateAssetByCustomer
} from './store'
import { initialAmountAdapter, reprocessAssetsAdapter } from './adapters'
import { useNavigate } from 'react-router-dom'

const Index = () => {

  const { customerAssets, assets, errors } = useSelector((state) => state.assetsDeclaration)
  const navigate = useNavigate()

  const dispatch = useDispatch()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialAmountAdapter(customerAssets)
    },
    onSubmit: (values) => {
      handleOnSubmit()
    }
  })

  const [inputs, setInputs] = React.useState({})

  React.useEffect(() => {
    setInputs(customerAssets)
  }, [customerAssets])

  React.useEffect(() => {
    dispatch(getAssets())
    dispatch(getAssetsByCustomer())
  }, [])

  React.useEffect(() => {
    if (assets?.length > 0) dispatch(setTempAssets(assets))
  }, [assets])

  const renderInputs = () => {
    // console.log(inputs)
    return Object.keys(inputs).map((key) => {
      const input = inputs[key]
      const lastOne = Object.keys(inputs).at(-1)
      const isLastOne = key === lastOne
      return (
        <AssetsGroup
          input={input}
          key={key}
          ID={key}
          lastOne={isLastOne}
          addInput={addInput}
          deleteInput={deleteInput}
          formik={formik}
        />
      )
    })
  }

  const addInput = () => {
    const lastOne = Object.keys(inputs).at(-1)
    const id = parseInt(lastOne) + 1
    const newInput = {
      id,
      type: 'CREATE',
      select: { id, value: undefined },
      text: { value: undefined, placeholder: '0.00' }
    }
    dispatch(addInputInState(newInput))
  }

  const deleteInput = (key) => {
    const input = inputs[key]
    if (input.type === 'CREATE') {
      dispatch(deleteInputInState({ id: key }))
    } else {
      dispatch(deleteAssetByCustomer({ id: input.id })).then((result) => {
        if (result.error) return
        dispatch(deleteInputInState({ id: key }))
      })
    }
  }

  const classes = useStyles()

  const evaluateErrors = () => {
    const stateErrors = Object.values(errors)
    const hasErrors = stateErrors.some((error) => {
      return error.select || error.text
    })
    return hasErrors
  }

  const handleOnSubmit = async (values) => {
    const hasErrors = evaluateErrors()
    if (hasErrors) return
    const data = reprocessAssetsAdapter(inputs)

    if (data.create.length === 0 && data.update.length === 0) {
      setTimeout(() => navigate('/NonCustomerPage/ScheduleAppointment'), 0)
      return
    }

    try {
      const createPromise =
        data.create.length > 0
          ? dispatch(saveAssetByCustomer(data.create))
          : Promise.resolve()
      const updatePromise =
        data.update.length > 0
          ? dispatch(updateAssetByCustomer(data.update))
          : Promise.resolve()

      await Promise.all([createPromise, updatePromise])

      navigate('/NonCustomerPage/ScheduleAppointment')
    } catch (error) {
      console.error('An error', error)
    }
  }

  return (
    <Box style={{ position: 'relative', minHeight: '50vh' }}>
      <FormikProvider value={formik}>
        <Form>
          <div style={{ flexGrow: 1 }}>{renderInputs()}</div>
        </Form>
      </FormikProvider>
      <ArrowButton
        showBtnNext={true}
        // link={'/NonCustomerPage/ScheduleAppointment'}
        back='/NonCustomerPage/ProofOfIncome'
        onClick={handleOnSubmit}
      />
    </Box>
  )
}

export default Index
