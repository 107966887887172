import React from "react";
import Box from "@mui/material/Box";
import HomeHeader from "../Components/HomeHeader/HomeHeader";
import Routes from "../Routes/Routes";

const Layout = () => {
  return (
    <Box>
      <HomeHeader />
      <Routes />
    </Box>
  );
};

export default Layout;
