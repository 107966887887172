import React , {useEffect} from "react";
import Box from "@mui/material/Box";
import { useStyles } from "./HomeStyle";
import { Typography } from "@mui/material";
import Card from "../../Components/Card/Card";
import { useDispatch, useSelector } from "react-redux"
import Actions from "../../Store/Types";
import { clearStateVerificationOTP } from '../../Components/Forms/LetsMeetForm/store'

const Home = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const letsMeetForm = useSelector((state) => state.LetsMeetFormReducer)

  useEffect(() =>{
    // LocalStorageService.clearToken()
    localStorage.clear()
    sessionStorage.clear()
    dispatch({ type: Actions.CLEARSTATE})
    dispatch(clearStateVerificationOTP())
  },[])
  return (
    <Box className={classes.Container}>
      <Box className={classes.SelectOptionWrapper}>
        <Typography className={classes.SelectText}>
          SELECT ONE OPTION
        </Typography>
      </Box>
      <Box className={classes.CardWrapper}>
        <Card />
      </Box>
    </Box>
  );
};

export default Home;
