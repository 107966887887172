import { Box, Button, CircularProgress, Typography } from '@mui/material'
import clock from '../../../assets/Images/clock.png'
import { useStyles } from '../Styles'
import { useNavigate } from 'react-router-dom'

const CustomModalForm = ({
  logo,
  description,
  loading,
  onSubmit,
  buttonSubmitTitle,
  prevText = 'BBL -',
  expirationTime = '15 minutes',
  showBackButton = false,
  FormInput = () => <></>
}) => {
  const classes = useStyles()
  const navigate = useNavigate()

  return (
    <Box mt={10}>
      <Box className={classes.PopupContainer}>
        <Box className={classes.PopupHeader}></Box>

        <Box className={classes.PopupMainSection}>
          <Box className={classes.PopupHeaderLogo}>
            <img className={classes.Logo} src={logo} alt="Logo" />
          </Box>
          <Typography className={classes.PopupText}>{description}</Typography>
          <Box className={classes.Code}>
            <Typography variant="h5"> {prevText} </Typography>
            <FormInput />
          </Box>
          <Box className={classes.PopupExpireTime}>
          <img className={classes.ClockImage} src={clock} alt="clock" />
            <Typography className={classes.ExpireTimeText}>
            The code will expire in {expirationTime}
          </Typography>
          </Box>
          <Box className={classes.PopupBtn}>
            <Button
              className={classes.PopupButton}
              onClick={onSubmit}
              sx={{ fontSize: '13px' }}
              disabled={loading}
            >
              {loading && <CircularProgress size={20} color="warning" />}
              {!loading && buttonSubmitTitle}
            </Button>
          </Box>
          {showBackButton && (
          <Box>
            <Button color='inherit' onClick={() => navigate(-1)}>back</Button>
          </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CustomModalForm
