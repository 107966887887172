import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useStyles } from "./NonCustomerPageStyle"
import Congratulations from "../../Components/Congratulations/Congratulations";

const ThankYouPage = () => {

  const classes = useStyles();
  useEffect(() => {
    window.onpopstate = (e) => {
      window.history.pushState(null,'')
    }
    window.history.pushState(null,'')
  }, [])
  return (
    <Box className={classes.MainContianer}>
    <Congratulations/>
    </Box>
  );
};

export default ThankYouPage;
