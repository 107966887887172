import Actions from "../Types";
import { Interceptor } from "../../Utils/Interceptor";

export function GetMessages(data) {
  const AxiosInterceptor = Interceptor();
  return async (dispatch, getState) => {
    const url = "/customer/bbank-important-messages";
    await AxiosInterceptor.get(url)
      .then((response) => {
        dispatch({
          type: Actions.GETMESSAGES,
          payload: response.data,
        });
      })
      .catch((e) => {

      });
  };
}
