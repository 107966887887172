import React from 'react'
import { Box } from '@mui/material'
import PopupSuccess from '../../../OtpPopup/PopupSuccess'
import ArrowButton from '../../../ArrowButton/ArrowButton'
import { useSelector } from 'react-redux'

const ReferenceNumber = () => {

  const { customer } = useSelector(state => state.letsMeet)

  return (
    <Box>
      <PopupSuccess referenceNumber={customer?.referenceNumber} />
      <ArrowButton
        link="/NonCustomerPage/yourLoanRequest"
        back="/NonCustomerPage/letsMeet"
      />
    </Box>
  )
}


export default ReferenceNumber