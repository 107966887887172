import Actions from "../Types";
import { Interceptor } from "../../Utils/Interceptor";

export function loanRequest(data, navigate) {

  const AxiosInterceptor = Interceptor()
  return async (dispatch, getState) => {
    const url = "/loan-requests";
    await AxiosInterceptor.post(url, data)
      .then((response) => {
        dispatch({
          type: Actions.LOANREQUEST,
          payload: response.data,
        });
        if(response.data.promoCode){
          localStorage.setItem('clprocode', response.data.promoCode)
          dispatch({
            type: Actions.SETISVALIDPROMOCODE,
            payload: { valid: true}
          })
        }

      })
      .catch((e) => {
        console.log(e.response.status, e.response.data.error.message);
        if(e.response.status === 404 || e.response.status === 400 ){
          localStorage.setItem('clprocode', '')
          dispatch({
            type: Actions.SETISVALIDPROMOCODE,
            payload: { valid: Math.random()}
          })
          // dispatch({
          //   type: Actions.PROMOCODEINVALID,
          //   payload: ''
          // })
          dispatch({
            type: Actions.SETMONTHLYINSTALLMENTWITHPROMOCODE,
            payload: null
          })
        }

        // if (e.response.status === 400) {
        //   dispatch({
        //     type: Actions.AGElIMIT,
        //     payload: { message: e?.response?.data?.error?.message },
        //   })
        //   navigate('/error-age-limit')
        // }
      });
  };
}
