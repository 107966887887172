import React from 'react'
import { Stack, Box } from '@mui/system'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import { useStyles } from './Style'
import InputLabel from '@mui/material/InputLabel'
import clsx from 'clsx'

const SelectInput = ({
  loading,
  Label = 'Select',
  options= [],
  setOpen,
  open,
  onChange,
  onBlur,
  value,
  placeholder,
  className,
  error
}) => {
  const classes = useStyles()

  // const [inputValue, setInputValue] = React.useState({})

  // React.useEffect(() => {
  //   console.log(value, "value")
  //   setInputValue(value)
  // }, [value])
  return (
    <Stack sx={{ width: '100%' }}>
      {/* <Box sx={{ display: 'flex', alignItems: 'flex-end' }}> */}
        {/* <Box sx={{ width: '100%' }}> */}
          <InputLabel
            shrink
            htmlFor="bootstrap-input"
            className={classes.SelectLable}
          >
            {Label}
          </InputLabel>
          <Autocomplete
            id="asynchronous-demo"
            // sx={{ width: '100%' }}
            open={open}
            // fullWidth={true}
            multiple={false}
            clearIcon={false}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            getOptionLabel={(option) => option?.name || ''}
            options={options}
            // loading={loading}
            onChange={onChange}
            // onBlur={onBlur}
            value={value}
            className={clsx(classes.Select, className)}
            classes={{ paper: classes.paper }} //* to change the style of the dropdown
            renderInput={(params) => (
              <TextField
              // label={Label}
              className={classes.input}
              placeholder={placeholder}
              {...params}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                mb: '10px'
              }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  )
                }}
              />
            )}
          />
        {/* </Box> */}
      {/* // </Box> */}
    </Stack>
  )
}

export default SelectInput
