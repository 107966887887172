import { makeStyles } from "@material-ui/core/styles";
import CongratsBg from "../../assets/Images/CongratsBg.png";

export const useStyles = makeStyles((theme) => ({
  CongratulationsContainer: {
    width: "100%",
    height: "100vh",
    display: "grid",
    placeItems: "center",
  },
  CongratulationsBox: {
    width: "772px",
    height: "745px",
    backgroundImage: "URL(" + CongratsBg + ")",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 790px)": {
      width: "500px",
      height: "500px",
    },
    "@media (max-width: 519px)": {
      width: "250px",
      height: "500px",
    },
  },
  SmilingEmoji: {
    width: "99px",
    height: "99px",
  },
  CongratulationsText: {
    "&.MuiTypography-body1": {
      fontSize: "26px",
      lineHeight: "35.52px",
      color: "#E40521",
      fontStyle: "italic",
      fontWeight: "800",
      fontFamily: theme.fonts.family.secondary,
      marginBottom: "14px",
      marginTop: "24px",
    },
  },
  ProcessText: {
    "&.MuiTypography-body1": {
      width: "450px",
      fontSize: "24px",
      lineHeight: "32.78px",
      color: "#575756",
      fontWeight: "400",
      textAlign: "center",
      fontFamily: theme.fonts.family.secondary,
      "@media (max-width: 520px)": {
        width: "300px",
      }
    },
  },
  ThankYouBtn: {
    "&.MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      marginTop: "77px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
}));
