import React, { useEffect, useState } from "react";
import { Box, InputLabel, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Input from "../Inputs/Input";
import InputWithAdornment from "../Inputs/InputWithAdornment";
import { useStyles } from "./LoanCalculatorStyle";
import Recomend from "../../assets/Images/Recomend.png";
import Button from "../Button/Button";
import Radio from "@mui/material/Radio";
import { Link, useNavigate } from "react-router-dom";
import Total from "../Inputs/Total";
import { useFormik } from "formik";
import { loanRequest } from "../../Store/Actions/LoanRequest";
import Actions from "../../Store/Types";
import { PromoCode } from "../../Store/Actions/PromoCode";
import { useDispatch, useSelector } from "react-redux";
import RadioChecked from "../../Store/Reducers/RadioCheckedReducer";

import { TooltipMessage } from "./TooltipMessage";
import { InputAdornment } from "@mui/material";
import useDebounce from "./useDebounce";
import InvalidPromoCode from "./InvalidPromoCode";
import { SetValidPromoCode } from "../../Store/Actions/SetIsPromoValid";
import previousMonday from "date-fns/fp/previousMonday";
import { LOANS_SUBTYPES } from '../../enums'
import { calculateAge } from '../../Utils/utils'


const LoanCalculator = ({ amount, terms, setError, setErrorTerms }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [showAlertPromoCode, setShowAlertPromoCode] = useState(false)

  const loand_request = useSelector((state) => state.LoanRequestReducer);
  const { radioChecked } = useSelector((state) => state.RadioChecked);

  const [promoCode, setPromoCode] = useState(loand_request.promoCode);
  // const [promoCode, setPromoCode] = useState(localStorage.getItem('clprocode'));
  // const [promoCode, setPromoCode] = useState(loand_request.promoCode);
  // const [promoCodeValue, setPromoCodeValue] = useState(
  //   loand_request?.promoCode ? loand_request?.promoCode : ""
  // );
  // const [radioChecked, setRadioChecked] = useState(
  //   loand_request.debtCancellationSchemeRequested || false
  // );

  useEffect(() => {
    setPromoCode(loand_request.promoCode)
  }, [loand_request.promoCode])

  const handleSubmitPromoCode = (vallenth) => {

    if (vallenth === 0) formik.handleSubmit();
  }

  const [showPromoCode, setShowPromoCode] = useState(false);

  // console.log(showPromoCode, promoCode)
  const [showModal, setShowModal] = useState(false);
  const fees = useSelector((state) => state.LoanRequestReducer.fees);
  const debtCancellationSchemeRequested = useSelector((state) => state.LoanRequestReducer.debtCancellationSchemeRequested);

  const monthlyInstallment = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallment
  );
  const monthlyInstallmentWithPromoCode = useSelector(
    (state) => state?.LoanRequestReducer?.monthlyInstallmentWithPromoCode
  );
  const debtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.debtCancellationSchemeFee
  );
  const totalDebtCancellationSchemeFee = useSelector(
    (state) => state?.LoanRequestReducer.totalDebtCancellationSchemeFee
  );
  const isPromoValid = useSelector(
    (state) => state?.LoanRequestReducer?.isPromoValid
  );

  const Monthly = monthlyInstallment;
  const formik = useFormik({
    initialValues: {
      requestedAmount: amount,
      requestedTerms: terms,
      promoCode: promoCode,
      interestRate: 2,
      debtCancellationSchemeRequested: radioChecked,
    },

    onSubmit: (values) => {
      amount = +amount;
      if (amount >= 1000 && terms > 0) {
        if (radioChecked !== null) {
          // console.log(values.promoCode, "submit promo");
          let formData = {
            requestedAmount: amount,
            requestedTerms: terms,
            promoCode: values.promoCode,
            loanSubTypeId: LOANS_SUBTYPES.unsecure.nonCustomer,
            loanTypeId: LOANS_SUBTYPES.unsecure.nonCustomer,
            interestRate: 2,
            debtCancellationSchemeRequested: radioChecked,
          };
          dispatch(loanRequest(formData, navigate));
        } else {
          let formData = {
            requestedAmount: amount,
            requestedTerms: terms,
            promoCode: values.promoCode,
            loanSubTypeId: LOANS_SUBTYPES.unsecure.nonCustomer,
            loanTypeId: LOANS_SUBTYPES.unsecure.nonCustomer,
            interestRate: 2,
            debtCancellationSchemeRequested: false,
          };
          dispatch(loanRequest(formData, navigate));
        }
      }
    },
  });
  // const debouncedPromoCode = useDebounce(formik.values.promoCode, 2500);
  // console.log(formik.values.promoCode, "submit promo");

  const handleClickPromoCode = () => {
    formik.handleSubmit()
  }

  const handleClick = () => {
    // dispatch({ type: Actions.SETISVALIDPROMOCODE, payload: { valid: true} })
    setShowAlertPromoCode(false);
  };
  useEffect(() => {
    if (amount) {
      formik.setFieldValue("requestedAmount", amount);
    } else {
      formik.setFieldValue("requestedAmount", 0);
    }
    if (terms) {
      formik.setFieldValue("requestedTerms", terms);
    } else {
      formik.setFieldValue("requestedTerms", 0);
    }
    if (amount >= 1000 && terms >= 12) {
      setError("");
      formik.handleSubmit();
    } else {
      if (amount < 1000) {
        setError("must be greater than 1000");
      } else {
        setError("");
      }
      if (terms < 12) setErrorTerms('Must be equal to or greater than 12 months')
      else setErrorTerms('')
    }
  }, [amount, terms]);

  // useEffect(() => {
  //   if (debouncedPromoCode && debouncedPromoCode != "") {
  //     formik.handleSubmit();
  //   }
  // }, [debouncedPromoCode]);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "BZD",
  });

  // useEffect(() => {
  //   dispatch(SetValidPromoCode(false));
  // }, []);

  // console.log(formik.values.promoCode, "promocode");
  useEffect(() => {
    if (isPromoValid !== null) {
      if (isPromoValid && isPromoValid.valid) {
        // setShowModal(false)
      } else {
        // setShowModal(true)
      }
    }

  }, [isPromoValid]);

  useEffect(() => {
    if (typeof loand_request?.isPromoValid?.valid === 'number' && String(loand_request.promoCode).length > 0) {
      setShowAlertPromoCode(true)
    }else {setShowAlertPromoCode(false) }
  }, [loand_request?.isPromoValid?.valid])

  useEffect(() => {
    if (String(formik.values.promoCode).length === 0) {
      setShowPromoCode(false);
    } else setShowPromoCode(true);
  }, [formik.values.promoCode]);

  useEffect(() => {
    dispatch({
      type: Actions.RADIOCHECKED,
      payload: debtCancellationSchemeRequested,
    });
  }, [debtCancellationSchemeRequested])

  const [showDCS, setShowDCS] = useState(false)

  const isEligibleForDCS = (dateOfBirth) => {
    if (!dateOfBirth) return false
    const age = calculateAge(dateOfBirth)
    return age >= 18 && age <= 65
  }

  useEffect(() => {
    setShowDCS(isEligibleForDCS(loand_request?.dateOfBirth))
  }, [loand_request])


  return (
    <Box className={classes.LoanCalculatorContainer}>
      <Box className={classes.LoanCalculatorHeader}>
        <Typography className={classes.CalculatorHeaderText}>
          LOAN CALCULATOR
        </Typography>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Stack
          direction="column"
          spacing={{ xs: 1, sm: 2, md: 4 }}
          mt={5}
          mb={6}
        >
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ xs: 1, sm: 2 }}
          >
            <Input
              // disabled
              className={classes.BlankInput}
              Lable="AMOUNT"
              name="requestedAmount"
              onChange={formik.handleChange}
              value={amount ? formatter.format(amount) : amount}
              readOnly
            />
            <Input
              // disabled
              className={classes.BlankInput}
              Lable="TERM"
              name="requestedTerms"
              onChange={formik.handleChange}
              value={terms}
              readOnly
            />
          </Stack>

          {showDCS && (
            <Stack sx={{ width: "100%" }}>
              <InputLabel className={classes.InputLable}>
                DEBT CANCELLATION SCHEME
              </InputLabel>
              {radioChecked === true && (
                <>
                  <Input
                    className={classes.BlankInput}
                    Lable="TOTAL DEBT CANCELLATION AMOUNT"
                    value={
                      totalDebtCancellationSchemeFee
                        ? formatter.format(totalDebtCancellationSchemeFee)
                        : totalDebtCancellationSchemeFee
                    }
                    readOnly
                  />
                </>
              )}
            </Stack>
          )}
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.RecomendLeftSec}
          >
            {showDCS && (
              <Box className={classes.RecomendImg}>
                <img src={Recomend} alt="" style={{ width: "301.07px" }} />
              </Box>
            )}
            <Stack className={classes.RecomendedInputs} sx={{ width: "100%" }}>
              <Total
                className={classes.LoanFees}
                Lable="LOAN FEES"
                Text={fees || 0}
                iconTolltip={false}
              />
            </Stack>
          </Stack>
          {showDCS && (
            <Box className={classes.RecomendRightSec}>
              <Link
                to="/NonCustomerPage/yourLoanRequest/TermsAndConditions"
                className={classes.Links}
              >
                <Button
                  className={classes.BtnTermsAndConditions}
                  Title="PERSONAL LOAN DEBT CANCELLATION SCHEME TERMS AND CONDITIONS"
                  BtnVariant="contained"
                  style={{ padding: 28 }}
                />
              </Link>
              <Stack direction="row" alignItems="center" mt={1}>
                <Radio
                  checked={radioChecked}
                  onClick={(e) => {
                    formik.handleSubmit();
                    dispatch({
                      type: Actions.RADIOCHECKED,
                      payload: !radioChecked,
                    });
                  }}
                />
                <InputLabel style={{ marginTop: 15, whiteSpace: "pre-wrap" }}>
                  By accepting these terms and conditions, I agree that I have
                  read the personal loan debt cancellation terms and conditions
                  and agree to pay the associated debt cancellation fee.
                </InputLabel>
              </Stack>
            </Box>
          )}
        </Stack>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={{ xs: 1, sm: 2 }}
          mt={3}
        >
          <Stack direction="column" className={classes.Promo}>
            <Total
              className={classes.MonthlyInsurence}
              Lable="ESTIMATED MONTHLY INSTALLMENT"
              style={{ fontSize: 20 }}
              Text={Monthly || 0}
              messageToltip={TooltipMessage.MONTHLY_INSTALLMENT}
            />
            <Box
              className={classes.EnterPromoCode}
              onClick={(values) => {
                setShowPromoCode(!showPromoCode)
                // setPromoCode((current) => !current);
              }}
            >
              <Typography>ENTER PROMO CODE</Typography>
            </Box>
          </Stack>
          <Stack direction="column" className={classes.Promo}>
            {showPromoCode && (
              <>
              <Input
                className={classes.PromoCodeInput}
                Lable="PROMO CODE"
                onChange={(e) => {
                  dispatch({
                          type: Actions.LOANREQUEST,
                          payload: {
                            ...loand_request,
                            promoCode: e.target.value
                          },
                        })
                        handleSubmitPromoCode(e.target.value.length)
                  formik.setFieldValue("promoCode", e.target.value);
                  // setPromoCodeValue(e.target.value);
                }}
                defaultValue={loand_request?.promoCode}
                // value={formik.values.promoCode}
                name="promoCode"
              />
              <Box
              className={classes.EnterPromoCode}
              onClick={handleClickPromoCode}
            >
              <Typography>VALIDATE PROMO CODE</Typography>
            </Box>
              </>
            )}
          </Stack>
        </Stack>

        <Stack direction="column" className={classes.Promo}>
          {(showPromoCode && monthlyInstallmentWithPromoCode) ? (
            <Total
              className={classes.MonthlyInsurence}
              Lable="YOUR NEW MONTHLY INSTALLMENT"
              // style={{ fontSize: 20 }}
              Text={typeof loand_request?.isPromoValid?.valid === 'boolean' ? (monthlyInstallmentWithPromoCode || 0) : 0}
              messageToltip={TooltipMessage.YOUR_NEW_MONTHLY_INSTALLMENT}
            />
          ): null}
        </Stack>
      </form>
      {showAlertPromoCode ? <InvalidPromoCode handleClick={handleClick} />: null}
    </Box>
  );
};

export default LoanCalculator;
