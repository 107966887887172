import { makeStyles } from "@material-ui/core/styles";
import { withTheme } from "styled-components";

export const useStyles = makeStyles((theme) => ({
Input: {
  width: "80%",
},



}));