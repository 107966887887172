// export const mockCustomerAssets = [
//   {
//     id: 1,
//     amount: 100000,
//     assetId: 2,
//     customerId: "example-02",
//     asset: {
//       id: 2,
//       name: "Casa",
//       description: "Casa",
//       position: 1,
//     }
//   }
// ]

export const initialInput = {
  1: {
    id: 1,
    type: 'CREATE',
    select: { id: 1, assetId: undefined },
    text: { value: undefined, placeholder: '0.00' },
  }
}

const customerAssetsAdapter = (assets = []) => {
  if (assets.length === 0) return { ...initialInput }
  const customerAssets = {}
  assets.forEach((asset) => {
    const { id, amount, assetId, customerId } = asset
    customerAssets[id] = {
      id,
      type: 'UPDATE',
      select: { id: assetId, assetId },
      text: { value: amount },
      customerId
    }
  })
  return customerAssets
}

export default customerAssetsAdapter