import * as yup from 'yup'

export const validationsSchemaPhone = yup.object({
  phone: yup
  .string()
  .test('len', 'Phone number must be exactly 10 characters', (val) => {
    if (val) return val.replace(/[-_]/g, '').length === 10
  })
  .required('**Please enter your Phone number')
})

export const validationsSchemaEmail = yup.object({
  email: yup
    .string('Email should be string')
    .email('Please provide a valid email address')
    .required('**Email is Required'),
  confirmEmailAddress: yup
    .string('Email should be string')
    .oneOf([yup.ref('email')], '**Email address must match')
    .required('**Email is Required')
})
