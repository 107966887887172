import React, { useEffect, useRef } from "react";
import { Box, Stack } from "@mui/material";
import ArrowButton from "../../ArrowButton/ArrowButton";
import { useStyles } from "./ScheduleAppointmentStyle3";
import Calendar from "../../../assets/Images/Calendar.png";
import { Typography } from "@material-ui/core";
import Button from "../../Button/Button";
import clsx from "clsx";
import { Link } from "react-router-dom";
import LocalStorageShedule from "../../../Utils/LocalStorageShedule";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
const ScheduleThankYou = () => {
  const customer_schedule = JSON.parse(localStorage.getItem("customer_schedule"));
  // console.log("customer_schedule", customer_schedule)
  const classes = useStyles();
  const inputFile = useRef(null);
  const firstName = customer_schedule?.firstName;
  const lastName = customer_schedule?.lastName;
  const branch = customer_schedule?.branch;
  const [pending, setPending] = React.useState(true)

  useEffect(() => {
    if (customer_schedule?.appointmentTime && customer_schedule?.appointmentDate) {
      setPending(false)
    }
  }, [customer_schedule])
  return (
    <Box>
      <Box className={classes.ScheduleAppointmentContainer}>
        <Box className={clsx(classes.ThankYouPoppup, classes.ApprovedPoppup)}>
          <Box
            className={clsx(
              classes.ApprovedPoppupImage,
              classes.ThankYouPoppupImage
            )}
          >
            <img
              className={clsx(classes.ApprovedImage, classes.ThankYouImage)}
              src={Calendar}
              alt=""
            />
          </Box>
          {!pending ? (
            <Typography className={classes.ThankYouText}>
              <strong>Dear {`${firstName} ${lastName}`}</strong> <br />
              Your appointment has been successfully scheduled. Please visit
              {` ${branch}`} branch on{" "}
              {moment(customer_schedule.appointmentDate).format("DD-MM-YYYY")}{" "}
              {moment(new Date(customer_schedule.appointmentTime)).format("hh:mm A")} Thank you
              for giving us the opportunity to serve you.
            </Typography>
          ) : (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          )}
          <Box className={classes.ThankYouBtn}>
            <Link to="/ProductsAndServices" className={classes.Link}>
              <Button Title="THANK YOU" />
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ScheduleThankYou;
