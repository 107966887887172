import React, { useEffect, useState } from 'react'
import { Beforeunload } from 'react-beforeunload'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './Theme/theme'
import Layout from './Routes/Layout'
import Backdrop from './Components/BackDrop/BackDrop'
import SnackBar from './Components/Snackbar/Snackbar'
import IsUservalid from './Utils/IsUservalid'


const App = () => {
  IsUservalid()

  const [isOnline, setOnline] = useState(true)

  const removeApplication = () => {
    if (!isOnline) {
      // Eliminar la cookie al cerrar el navegador
      // document.cookie = 'active=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }
  }

  useEffect(() => {
    // document.cookie = 'active=true'
    console.table({version: "2.0.1"})
  }, [])

  return (
    <Beforeunload onBeforeunload={removeApplication}>
      <ThemeProvider theme={theme}>
          <Backdrop />
          <SnackBar />
          <Layout />
      </ThemeProvider>
    </Beforeunload>
  )
}

export default App
