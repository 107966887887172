import React from 'react'
import { Box, Stack } from '@mui/system'
import { Typography } from '@mui/material'
import { useStyles } from './LetsMeetFormStyle'

import LetsMeetForm from './LetsMeetForm'
import TelephoneValidation from './TelephoneValidation'
import { useDispatch, useSelector } from 'react-redux'
import {
  setValues as handleOnchange,
  initValidationEmail,
  initValidationPhone,
  resendOTPEmail,
  resendOTPPhone,
  setModalOpen,
  setValid,
  validateOTPEmail,
  validateOTPPhone
} from './store'
import MailValidation from './MailValidation'
import { validateError } from '../../../Utils/validateError'
import ModalError from '../../modal/error'
import toast, { Toaster } from 'react-hot-toast'
import Actions from '../../../Store/Types'
import { Outlet, useLocation } from 'react-router-dom'

const Index = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const Location = useLocation()
  const { valid, isModalOpen, values, otp } = useSelector(
    (state) => state.letsMeet
  )

  const [readMode, setReadMode] = React.useState(true)
  const [disabled, setDisabled] = React.useState({
    verifyPhone: true,
    verifyEmail: true
  })
  const [sending, setSending] = React.useState({
    phone: false,
    email: false,
    otpPhone: false,
    otpEmail: false
  })
  const [errorVerificationPhone, setErrorVerificationPhone] = React.useState({
    open: false,
    message: ''
  })
  const [errorVerificationEmail, setErrorVerificationEmail] = React.useState({
    open: false,
    message: ''
  })

  const phoneReplace = (phone) => phone.replace(/[-_]/g, '')

  const isPhoneValid = (phone = '', length = 10) => {
    const phoneValue = phoneReplace(phone)
    return phoneValue.length === length
  }

  React.useEffect(() => {
    if (valid.phone && valid.email) setReadMode(false)
    else setReadMode(true)
  }, [valid])

  React.useEffect(() => {
    const unsurePhone = isPhoneValid(values.phone)
    if (!unsurePhone) setDisabled({ ...disabled, verifyPhone: true })
    else setDisabled({ ...disabled, verifyPhone: false })
  }, [values])

  const sendPhoneToAPI = () => {
    const unsurePhone = isPhoneValid(values.phone)
    // const phone = phoneReplace(values.phone)
    const phone = values?.phone
    if (!unsurePhone) return
    setDisabled({ ...disabled, verifyPhone: true })
    setSending({ ...sending, phone: true })
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch(initValidationPhone(phone))
    .then((response) => {
      const error = validateError(response)
      console.log('response >>>', response)
      if (error.error) {
        onCloseModalPhone()
        return toast.error(error.message)
      }
    })
    .finally(() =>
      dispatch({ type: Actions.LOADING, payload: false })
    )
  }

  const onCloseModalPhone = () => {
    dispatch(setModalOpen({ modal: 'phone', value: false }))
    if (!valid.phone) {
      setDisabled({ ...disabled, verifyPhone: false })
      setSending({ ...sending, phone: false })
    }
  }

  const onCloseModalEmail = () => {
    dispatch(setModalOpen({ modal: 'email', value: false }))
    if (!valid.email) {
      setDisabled({ ...disabled, verifyEmail: false })
      setSending({ ...sending, email: false })
    }
  }

  const onSubmitVerificationOTPPhone = () => {
    if (!otp.phone || otp.phone.length < 5) return
    const data = { otp: otp.phone, phone: values.phone }
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch(validateOTPPhone(data))
      .then((response) => {
        const error = validateError(response)
        if (error.error) {
          return setErrorVerificationPhone({
            open: error.error,
            message: error.message
          })
        } else setErrorVerificationPhone({ open: false, message: '' })
        toast.success('Phone verified successfully', { position: 'top-right' })
        // dispatch(setValid({ nameState: 'phone', value: true })) // set phone as valid
        // dispatch(setModalOpen({ modal: 'phone', value: false }))
      })
      .catch((err) => console.log('onSubmitVerificationOTPPhone >>>', err))
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const resendOTPPhoneAPI = () => {
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch(resendOTPPhone())
      .then((response) => {
        const error = validateError(response)
        if (error.error) {
          return toast.error(error.message)
        }
        setErrorVerificationPhone({ open: false, message: '' })
        toast.success('OTP sent successfully', {
          position: 'top-right'
        })
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const resendOTPEmailAPI = () => {
    dispatch({ type: Actions.LOADING, payload: true })
    dispatch(resendOTPEmail())
      .then((response) => {
        const error = validateError(response)
        if (error.error) {
          return toast.error(error.message)
        }
        setErrorVerificationEmail({ open: false, message: '' })
        toast.success('OTP sent successfully', {
          position: 'top-right'
        })
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const reEnterOTPPhone = () => {
    setErrorVerificationPhone({ open: false, message: '' })
    dispatch(setModalOpen({ modal: 'phone', value: true }))
  }

  const reEnterOTPEmail = () => {
    setErrorVerificationEmail({ open: false, message: '' })

    dispatch(setModalOpen({ modal: 'email', value: true }))

  }

  const sendEmailToAPI = (email) => {
    if (!email) return console.log('Email is required')

    setDisabled({ ...disabled, verifyEmail: true })
    setSending({ ...sending, email: true })

    dispatch({ type: Actions.LOADING, payload: true })
    dispatch(initValidationEmail(email))
      .then((response) => {
        const error = validateError(response)
        if (error.error) {
          onCloseModalEmail()
          return toast.error(error.message)
        }
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  const sendOTPEmail = () => {
    if (!otp.email || !values.email) return toast.error('OTP is required')

    const sendData = {
      email: values.email,
      otp: otp.email
    }

    dispatch({ type: Actions.LOADING, payload: true })

    dispatch(validateOTPEmail(sendData))
      .then((response) => {
        const error = validateError(response)
        if (error.error) {
          return setErrorVerificationEmail({
            open: error.error,
            message: error.message
          })
        } else setErrorVerificationEmail({ open: false, message: '' })
        toast.success('Phone verified successfully', { position: 'top-right' })
      })
      .finally(() => dispatch({ type: Actions.LOADING, payload: false }))
  }

  return (
    <Box>
      <Toaster toastOptions={{ position: 'bottom-center' }}
      />

      {Location.pathname === '/NonCustomerPage/letsMeet/opt' ? (
        <Stack mb={10} mt={10}>
          <Outlet />
        </Stack>
      ) : (
        <>
          <Typography variant="h6" className={classes.FormTitle}>
            PLEASE COMPLETE THE INFORMATION BELOW
          </Typography>
          <Typography variant="subtitle1" className={classes.SubTitle}>
            "*" Mandatory Field
          </Typography>

          <TelephoneValidation
            isVerified={valid.phone}
            openVerification={isModalOpen.phone}
            handleChange={(value) =>
              dispatch(handleOnchange({ input: 'phone', value }))
            }
            value={values.phone}
            buttonDisabled={disabled.verifyPhone}
            initValidation={sendPhoneToAPI}
            onClose={onCloseModalPhone}
            pending={sending}
            onSubmitOTPVerification={onSubmitVerificationOTPPhone}
          />

          <ModalError
            error={errorVerificationPhone}
            onClose={onCloseModalPhone}
            onCLickReEnter={reEnterOTPPhone}
            onClickResSend={resendOTPPhoneAPI}
          />

          <MailValidation
            isVerified={valid.email}
            openVerification={isModalOpen.email}
            initValidation={sendEmailToAPI}
            handleChange={(value) =>
              dispatch(handleOnchange({ input: 'email', value }))
            }
            onClose={onCloseModalEmail}
            pending={sending}
            onSubmitOTPVerification={sendOTPEmail}
          />

          <ModalError
            error={errorVerificationEmail}
            onClose={onCloseModalEmail}
            onCLickReEnter={reEnterOTPEmail}
            onClickResSend={resendOTPEmailAPI}
          />

          <hr className={classes.Hr} />
          <LetsMeetForm readMode={readMode} />
        </>
      )}
    </Box>
  )
}

export default Index
