import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  ScheduleAppointmentContainer: {
    width: "100%",
  },
  Heading: {
    "&.MuiTypography-root": {
      fontSize: "13px",
      fontWeight: 500,
      lineHeight: "18px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
      marginBottom: "31px",
      color: theme.colors.primary.Grey,
    },
  },
  UploadFiles: {
    "&.MuiButtonBase-root": {
      width: "100%",
      justifyContent: "space-between",
      height: "43px",
      border: " 1px solid #DADADA",
      color: "#B2B2B2",
      fontSize: "13px",
      paddingLeft: "30px",
      fontWeight: "500",
      letterSpacing: "0.1em",
      lineHeight: "17.76px",
      fontFamily: theme.fonts.family.tertiary,
    },
    "@media (max-width: 515px)": {
      fontSize: "9px !important",
      height: "auto !important",
    },
  },
  SubmitBtn: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "100px",
    "& .MuiButtonBase-root": {
      width: "176px",
      height: "39px",
      backgroundColor: "#005FAA",
      color: "#ffff",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "15.51px",
      letterSpacing: "0.2em",
      fontFamily: theme.fonts.family.primary,
    },
  },
  UploadFileText: {
    "&.MuiTypography-root": {
      marginTop: "5px",
      color: "#706F6F",
      fontSize: "13px",
      fontWeight: "400",
      lineHeight: "18px",
      fontFamily: theme.fonts.family.tertiary,
    }
  },
  uploadFileName: {
    fontFamily: theme.fonts.family.secondary,
    fontSize: 15,
    fontWeight: 400,
    color: theme.colors.secondary.Green,
    // width:500,
    marginTop:10,
    marginBottom:15

  },
  deleteFileIcon: {
    "&.MuiSvgIcon-root": {
      color:" red",
      marginTop: 0,
      fontSize: "large",
      marginLeft: 10,
      position: "absolute"
    },
  },
  FileInput: {
    "& .MuiInputLabel-root": {
      fontSize: "13px",
      fontWeight: "500",
      lineHeight: "17.76px",
      letterSpacing: "0.1em",
      color: "#706F6F",
    },
  },
  
  PlusIcon: {
    "&.MuiSvgIcon-root": {
      color: "#F07F3C",
      width: "21px",
      height: "21px",
    },
  },
  RemoveIcon: {
    "&.MuiSvgIcon-root": {
      width: "21px",
      height: "21px",
      color: "#F07F3C",
    },
  },
  UploadLabel: {
    color: "#706F6F",
    fontSize: "14px",
    fontFamily: "avenirLight !important",
    fontWeight: "500",
    lineHeight: "20px",
    textTransform: "uppercase"
  },
  Mt16: {
    marginTop: "16px"
  }
}));
