import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { useStyles } from "./HelpTooltipStyle";
import { Stack, Typography } from "@mui/material";
import WhatsAppIcon from "../../../assets/WhatsAppIcon.svg";
import axios from "axios";
import { baseUrl } from "../../../baseUrl";

const HelpTooltip = () => {
  const [WhatsAppNo, setWhatsAppNo] = useState("");

  useEffect(() => {
    axios
      .get(`${baseUrl}/config/whatsappphonenumber`)
      .then((response) => {
        setWhatsAppNo(response.data.value.replace(/(501)/g, ''));
      })
      .catch((error) => {
      });
  }, []);

  const classes = useStyles();
  return (
    <Stack alignContent={'center'}>
      <Box className={classes.TooltipContainer}>
      <Box className={classes.TooltipWrapper}>
        <Box className={classes.IconContainer}>
          <img src={WhatsAppIcon} alt="" className={classes.Icon} />
        </Box>
        <Box className={classes.ContentContainer}>
          <Typography variant="subtitle1" className={classes.Content}>
            Whatsapp us at <strong> {WhatsAppNo} </strong> or call us at{" "}
            <strong> 221-8883 </strong>
            between 8:00 a.m. and 7:00 p.m. Monday to Friday.
          </Typography>
        </Box>
      </Box>
    </Box>
    </Stack>
  );
};

export default HelpTooltip;
