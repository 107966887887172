import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  MainContianer: {
    //width: "100%",
    margin: "3rem auto auto auto",
    "@media (max-width: 788px)": {
      width: "100%",
      margin: "auto",
      padding: "0"
    }
  },
  FormTitle: {
    "&.MuiTypography-root": {
      marginTop: "3rem",
      fontFamily: theme.fonts.family.secondary,
      fontSize: 16,
      fontWeight: 800,
      color: theme.colors.primary.Darkblue,
      letterSpacing: "0.1em",
    },
  },
  FormContainer: {
    marginTop: "2.5rem",
  },
  Links: {
    textDecoration: "none",
  },
}));
