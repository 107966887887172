import React, { Fragment, useState } from "react"
import NotCustomerYet from "./NotCustomerYet"
import Box from "@mui/material/Box"
import { Select, Typography } from "@mui/material"
import Input from "../Inputs/Input"
import { useStyles } from "./NotCustomerYetStyle"
import Stack from "@mui/material/Stack"
import Button from "../Button/Button"
import Warning from "../../assets/Warning.png"
import { Link } from "react-router-dom"
// import Select from "./SelectMethod"
import { useDispatch, useSelector } from "react-redux"
import * as yup from "yup"
import InputMask from "react-input-mask"
import Validation2 from "./Validation2"
import InputTooltip from "./InputTooltip"
import MenuItem from "@mui/material/MenuItem"
import { Formik } from "formik"
import axios from "axios"
import AlreadyCustomerContainer from "../AlreadyCustomer/AlreadyCustomerContainer"
import Actions from "../../Store/Types"
import { baseUrl } from "../../baseUrl"

const NotCustomerYetContainer = ({ modal, setHideFields }) => {
  const dispatch = useDispatch()
  const formData = useSelector((state) => state.LetsMeetFormReducer)
  const [matchingStatus, setMatchingStatus] = useState(0)
  const handleFormSubmit = (values) => {
    const data = {
      firstName: formData.firstName,
      middleName: formData.middleName,
      lastName: formData.lastName,
      dateOfBirth: formData.dateOfBirth,
      accountType: values.accountType,
      accountNumber: String(values.accountNumber),
    }
    
    const url = `${baseUrl}/customers/validation/account`
    axios
      .post(url, { ...data })
      .then(({ data }) => {
        setMatchingStatus(data.matchingStatus)
        dispatch({
          type: Actions.ACCOUNTMATCHINGSTATUS,
          payload: data.matchingStatus,
        })
        if (data.matchingStatus === 9) {
          setHideFields({
            display_fields: "",
            display_already_customer: "none",
            display_not_customer: "none",
            display_validatio_address: "none",
          })
        }
      })
      .catch((e) => {
   
      })
  }

  const validationSchema = yup.object({
    accountNumber: yup
      .string()
      .required("**Please enter your First Name")
      .min(15),
  })

  const initialValues = {
    accountNumber: "",
    accountType: "Debit card number",
  }
  const  [ accountType ,setAccountType ] = useState("Debit card number")
  
  const classes = useStyles()

  return (
    <Fragment>
      {matchingStatus === 0 ? (
        <NotCustomerYet ModalOne={modal}>
          <Box className={classes.MainContainer}>
            <Box className={classes.CardContainer}>
              <Box className={classes.IconWrapper}>
                <img src={Warning} alt="" className={classes.Warning} />
              </Box>
              <Box className={classes.Wrapper}>
                <Typography variant="subtitle1" className={classes.Text}>
                Our records show that you may already be a Belize Bank customer. Belize Bank customers can participate in our
                  <strong> loyalty program </strong> and{" "}
                  <strong>enjoy lower interest rates. </strong> 
                  For final confirmation, please enter the following information.
                </Typography>
                <Box className={classes.Form}>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={(values) => handleFormSubmit(values)}
                    validationSchema={validationSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue,
                    }) => {
                      return (
                        <>
                          <Stack
                            direction={{ xs: "column", sm: "row" }}
                            spacing={{ xs: 1, sm: 2, md: 2 }}
                            className={classes.FormRow}
                          >
                            <Select
                              labelId="select-account"
                              defaultValue={"Debit card number"}
                              onChange={(e) => {
                                setFieldValue('accountType',e.target.value)
                                setAccountType(e.target.value)
                              }}
                              name="accountType"
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              className={`${classes.SelectCustomer} ${classes.InputCustomer}`}
                              MenuProps={{
                                classes: { paper: classes.Dropdown },
                              }}
                            >
                              <MenuItem value="Debit card number">
                                Debit Card Number
                              </MenuItem>
                              <MenuItem value="Bank account number">
                                Belize Bank account number
                              </MenuItem>
                              <MenuItem value="Debit card number">
                                Belize Bank debit card Number
                              </MenuItem>
                            </Select>
                            <InputMask
                              mask={accountType == "Bank account number" ? "999999999999999" : "9999999999999999"}
                              alwaysShowMask={false}
                              name="accountNumber"
                              value={values.accountNumber}
                              onChange={handleChange}
                            >
                              {(inputProps) => (
                                <Input
                                  className={classes.InputCustomer}
                                  type="text"
                                  {...inputProps}
                                  placeholder="Account Number"
                                  // Tooltiptitle={
                                  //   <InputTooltip Title="BBL account numbers have 15 digits. Please correct." />
                                  // }
                                  
                                />
                              )}
                            </InputMask>
                          </Stack>

                          <Stack
                            className={classes.stackButton}
                            direction="row"
                          >
                            <Link to="" className={classes.Links}>
                              <Button
                                Title="VALIDATE MY INFORMATION"
                                variant="subtitle1"
                                className={classes.Button}
                                BtnVariant="contained"
                                onClick={handleSubmit}
                              />
                            </Link>
                          </Stack>
                        </>
                      )
                    }}
                  </Formik>
                </Box>
              </Box>
            </Box>
          </Box>
        </NotCustomerYet>
      ) : (
        ""
      )}
      {matchingStatus === 1 ? <AlreadyCustomerContainer ssnExist={true} /> : ""}
    </Fragment>
  )
}

export default NotCustomerYetContainer
