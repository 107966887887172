import Group from "../../assets/Images/Group.png";

export const List = [
  {
    id: "1",
    image: Group,
    text: "Having a Debt Cancellation Scheme ensures that your debt will be financially taken care of in the event of your death.",
  },
  {
    id: "2",
    image: Group,
    text: "Your family will not need to repay your debt",
  },
  {
    id: "3",
    image: Group,
    text: "The Bank will not have to collect the debt from your securities",
  },
];
