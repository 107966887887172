import * as React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const AlreadyCustomer = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        "& > :not(style)": {
          width: "1017.93px",
          height: "282.58",
          backgroundColor: "#FEF1E7",
          borderRadius: 2,
          justifyContent: "center",
          margin: "10rem",
          filter: "drop-shadow(4px 4px 3px rgba(91, 91, 91, 0.349))",
          ["@media (max-width: 1000px)"]: {
            width: "100%",
            height: "auto",
          },
          ["@media (max-width: 500px)"]: {
            width: "90%",
            margin: "6rem",
          },
        },
      }}
    >
      <Paper elevation={3}>{props?.children}</Paper>
    </Box>
  );
};

export default AlreadyCustomer;