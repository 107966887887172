import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  TermsAndConditionsContainer: {
    width: "1053px",
    margin: "96px auto",
    ["@media (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  TermsAndConditionsHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "56.52px",
    backgroundColor: "#56AF31",
    "& .MuiTypography-root": {
      fontSize: "19.4px",
      color: "#ffff",
      fontWeight: "500",
      lineHeight: "26.5px",
      letterSpacing: "0.1em",
      fontFamily: theme.fonts.family.secondary,
    },
  },
  RecomendImage: {
    width: "318.48px",
    height: "84.38px",
    margin: "34.88px 0 29.28px 0",
  },
  Content: {
    width: "790px",
    marginBottom: "37.54px",
    "& .MuiTypography-root": {
      fontSize: "20px",
      color: "#706F6F",
      fontWeight: "300",
      lineHeight: "27px",
      fontFamily: theme.fonts.family.tertiary,
    },
    ["@media (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  BenefitList: {
    width: "790px",
    ["@media (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  BenefitListHeader: {
    display: "flex",
    alignItems: "center",
    padding: "10px 0px 10px 32px",
    background: "#BCDFF7",
    "& .MuiTypography-root": {
      fontSize: "20px",
      color: "#707070",
      fontWeight: "400",
      lineHeight: "27px",
      fontFamily: theme.fonts.family.tertiary,
    },
  },
  ListItem: {
    width: "750px",
    "& .MuiTypography-root": {
      fontSize: "20px",
      color: "#707070",
      fontWeight: "400",
      lineHeight: "27px",
      fontFamily: theme.fonts.family.tertiary,
    },
    ["@media (max-width: 1100px)"]: {
      width: "100%",
    },
  },
  ListItemImage: {
    width: "28px",
  },
  BackBtn: {
    width: "176px",
    height: "39px",

    "&.MuiButton-root": {
      background: "#005FAA",
      marginTop: "57.63px",
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
    },
    "& .MuiTypography-root": {
      fontSize: "13px",
      color: "#ffff",
      fontWeight: "600",
      lineHeight: "15.51px",
      fontFamily: theme.fonts.family.primary,
      letterSpacing: "0.2em",
    },
  },
  Links: {
    textDecoration: "none",
  },
}));
