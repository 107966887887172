import { Interceptor } from "../../Utils/Interceptor"
import Actions from "../Types"
import LoanDetails from "../../Components/LoanDetails/LoanDetails"
import { LoanTermsDetails } from "../../Components/LoanDetails/LoanTermDetails"
import { LoanAmountDetails } from "../../Components/LoanDetails/LoanAmountDetails"

export const GetLoandRequest = () => {
  const AxiosInterceptor = Interceptor()
  return async (dispatch, getState) => {
    const url = "/loan-requests"
    await AxiosInterceptor.get(url)
      .then(({ data }) => {
              dispatch({
          type: Actions.SETLOADNREQUEST,
          payload: { ...data },
        })
        dispatch({
          type: Actions.SETAMOUNTDETAILS,
          payload: data.requestedAmount,
        })
        dispatch({
          type: Actions.SETTERMSTDETAILS,
          payload: data.requestedTerms,
        })
      })
      .catch((e) => {
        console.log("ERROR >>>", e)
        // dispatch({
        //  type: Actions.CLEARLOANREQUESTSTATE
        // })
      })
  }
}
