import * as React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useStyles } from "./Style";

export default function CheckboxLabels({ onChange }) {
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
      className={classes.Wrapper}
        control={<Checkbox onChange={onChange} className={classes.Checkbox} />}
        label="I don’t have a bill under my name"
      />
    </FormGroup>
  );
}
