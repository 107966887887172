import React, { useState, useLayoutEffect, useEffect, Fragment } from "react";
import { useStyles } from "./ProcessStepsStyle";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { ProcessArray } from "./ProcessArray";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

const ListItem = ({ steps, setProgrress }) => {
  const classes = useStyles();
  const Location = useLocation();

  useEffect(() => {
    if (Location.pathname.includes(steps.Link)) {
      steps.id !== 1 && setProgrress(steps.id * 12.5);
    }
  }, [Location]);

  return (
    <Box
      key={steps}
      className={clsx(
        Location.pathname.includes(steps.Link)
          ? classes.Active
          : classes.StepWrapper
      )}
    >
      <img src={steps.Icon} alt="" className={classes.Icons} />
      <Typography
        variant="subtitle1"
        className={clsx(
          Location.pathname.includes(steps.Link)
            ? classes.ActiveTitle
            : classes.Title
        )}
      >
        {steps.Title}
      </Typography>
      <ArrowDropDownIcon className={classes.DownArrowIcon} />
    </Box>
  );
};

const ProcessSteps = ({ setProgrress, steps, tabs }) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const Location = useLocation();

  const FINAL_LINKS = [
    '/NonCustomerPage/ScheduleAppointment/MakeAppointment',
    '/NonCustomerPage/ScheduleAppointment/ScheduleApproved',
    '/NonCustomerPage/ScheduleAppointment/AppointmentPending'
]

  React.useEffect(() => {
    FINAL_LINKS.forEach(link => {
      if (Location.pathname === link) return setProgrress(100)
    })
  }, [Location])
  

  return (
    <Box className={classes.MainContainer}>
      {ProcessArray.map((steps) => {
        return (
          <Fragment key={steps.id}>
            {width > 1100 ? (
              <ListItem steps={steps} setProgrress={setProgrress} />
            ) : (
              <>
                {width <= 1100 && Location.pathname.includes(steps.Link) && (
                  <ListItem steps={steps} setProgrress={setProgrress} />
                )}
              </>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

export default ProcessSteps;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useLayoutEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
