import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import Input from '../../Inputs/Input'
import { Stack } from '@mui/system'
import { Button, Box, Modal } from '@mui/material'
import { validationsSchemaPhone } from './validations'
import { useFormik } from 'formik'
import { ModalForm, OTPForm } from './components'
import SmsText from '../../../assets/Images/sms-text.png'
import clsx from 'clsx'
import { useStyles } from './LetsMeetFormStyle'
import { CheckCircle } from '@mui/icons-material'

const TelephoneValidation = ({
  isVerified = false,
  pending = { phone: false, otpPhone: false },
  openVerification = false,
  value = '',
  handleChange = (e) => console.log(e),
  initValidation = () => console.log('Verification clicked'),
  buttonDisabled = false,
  onSubmitOTPVerification = () => console.log('Submit OTP'),
  onClose = () => console.log('Close Modal')
}) => {
  const formik = useFormik({
    initialValues: { phone: value },
    validationSchema: validationsSchemaPhone,
    onSubmit: initValidation
  })

  const classes = useStyles()
  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (isVerified || pending.phone) setDisabled(true)
    else setDisabled(false)
  }, [isVerified, pending.phone])

  return (
    <Stack direction="row" spacing={2} mt={3} alignItems="center">
      <form
        onSubmit={formik.handleSubmit}
        style={{ display: 'flex', alignItems: 'baseline', width: '100%' }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <InputMask
            mask="501-699-9999"
            alwaysShowMask={false}
            name="phone"
            value={formik.values.phone}
            onChange={(e) => {
              formik.handleChange(e)
              handleChange(e.target.value)
            }}
            className={clsx({ [classes.InputDisabled]: disabled })}
            readOnly={disabled}
            disabled={disabled}
          >
            {(inputProps) => (
              <Input
                Lable="MOBILE NUMBER*"
                error={formik.touched.phone && formik.errors.phone}
                {...inputProps}
                disabled={disabled}
                readOnly={disabled}
                type="tel"
              />
            )}
          </InputMask>
        </Box>

        <Box sx={{ ml: 2 }}>
          {!isVerified && (
          <Button
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
            onClick={initValidation}
            type="submit"
          >
            Verify
          </Button>
          )}
          {isVerified && (
            <Button
              variant="outlined"
              color="inherit"
              disabled
          >
            <CheckCircle color="success" />
          </Button>
          )}
        </Box>
      </form>
      {openVerification && (
        <Modal
          open={openVerification}
          onClose={onClose}
          disableAutoFocus={false}
        >
          <ModalForm
            description={`Please enter the OTP sent to your mobile number.`}
            prevText={''}
            FormInput={OTPForm}
            loading={pending.otpPhone}
            buttonSubmitTitle={'Confirm'}
            onSubmit={onSubmitOTPVerification}
            logo={SmsText}
            // showBackButton={true}
          />
        </Modal>
      )}
    </Stack>
  )
}

export default TelephoneValidation
