import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({

  MainContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
    ["@media (max-width: 500px)"]: {
      width: "100%",
    },
  },

  CardContainer: {
    display: "flex",
    justifyContent: "center",
    margin: "1.1rem",
    position: "relative",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
    },
    ["@media (max-width: 500px)"]: {
      width: "90%",
      margin: "1.1rem 1.8rem",
    },
  },
  IconWrapper: {
    position: "absolute",
    top: -1,
  },
  Wrapper: {
    padding: "4rem 6.5rem",
    ["@media (max-width: 1000px)"]: {
      padding: "3rem 3rem",
    },
    padding: "4rem 6.5rem",
    ["@media (max-width: 500px)"]: {
      padding: "3rem 1rem",
    },
  },
  Smile: {
    width: "6.95rem",
    height: "6.95rem",
  },

  Text: {
    position: "relative",
    top: "70px",
    textAlign: "center",
    fontFamily: theme.fonts.family.secondary,
    fontWeight: "800",
    fontSize: "1.1rem",
    lineHeight: "1.5rem",
  },

  stackButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3.5rem",
    alignItems: "center",
    gap: "0.8rem",
    ["@media (max-width: 1000px)"]: {
      width: "100%",
      flexWrap: "wrap",
    },
    ["@media (max-width: 500px)"]: {
      width: "100%",
      flexWrap: "wrap",
    },
  },

  Button: {
    position: "relative",
    top: "40px",
    width: "18.13rem",
    height: "1.95rem",
    "&.MuiButtonBase-root": {
      borderRadius: 7,
      backgroundColor: theme.colors.primary.Darkblue,
      "&:hover": {
        backgroundColor: theme.colors.secondary.Green,
      },
      "& .css-10wpov9-MuiTypography-root": {
        fontFamily: theme.fonts.family.primary,
        fontSize: "0.81rem",
        fontWeight: "600",
        letterSpacing: "0.2em",
      },
    },
  },


  Links: {
    textDecoration: "none",
  },
}));