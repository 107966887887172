import * as React from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useStyles } from "./Style";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@material-ui/core";
import moment from "moment";

export default function BasicTimePicker({ placeholder, Label, minTime, setValueTime, onError, onChange, appointmentDate = moment("2023-12-12T12:00:00"), ...props }) {
  const [value, setValue] = React.useState(moment(appointmentDate));
  const classes = useStyles();
  console.log('MinTime:', appointmentDate)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box className={classes.DateWrapper}>
        <InputLabel
          shrink
          htmlFor="bootstrap-input"
          className={classes.TimeLable}
        >
          {Label}
        </InputLabel>
        <TimePicker
          className={classes.TimePicker}
          // value={minTime}
          value={appointmentDate}
          minTime={moment(minTime) || moment("2018-01-01T08:00")}
          // minTime={moment("2018-01-01T08:00")}
          maxTime={moment("2022-12-15T14:30")}
           shouldDisableTime={(timeValue, clockType) => {
             return clockType === "minutes" && timeValue >= 31;
           }}
           onError={onError}
           onChange={onChange}
          // onChange={(newValue) => {
          //   setValue(newValue);
          //   setValueTime(newValue);
          // }}
          minutesStep={60}
          renderInput={(params) => (
            <TextField
              {...props}
              fullWidth
              placeholder={placeholder}
              {...params}
              sx={{
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
                "& .MuiInputLabel-formControl": {
                  display: "none",
                },
              }}
            />
          )}
        />
      </Box>
    </LocalizationProvider>
  );
}
