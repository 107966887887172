import React from "react";
import { Typography } from "@mui/material";
// import Success from "../../assets/Images/Success.png";
import { Box, Stack } from "@mui/system";
import { useStyles } from "./OtpPopUpStyle.js";

const PopupSuccess = ({ referenceNumber }) => {
  const classes = useStyles();


  return (
    <Stack>
      <Box className={classes.SuccessDiscription}>
        <Typography className={classes.SuccessDiscriptionText}>
          Your online loan application reference number is{" "}
          <strong>"{referenceNumber}"</strong>
          <br />
          This number is valid for 3 months. Please securely save a copy of it
          for future use.
        </Typography>
      </Box>
    </Stack>
  );
};

export default PopupSuccess;
