import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import UseJwt from '../../../auth/jwt/Jwt'

export const verifyReferenceNumber = createAsyncThunk('appReferenceNumber/verifyReferenceNumber', async (referenceNumber) => {
  try {
  const response = await UseJwt.verifyReferenceNumber(referenceNumber)
  return response.data
  } catch (error) {
    // console.log('error:', error.response.data.error)
    throw Error(error.response.data.error.message)
  }
})

export const verifyPhoneNumber = createAsyncThunk('appReferenceNumber/verifyPhoneNumber', async (phoneNumber) => {
  try {
  const response = await UseJwt.verifyPhoneNumber(phoneNumber)
  return response.data
  } catch (error) {
    // console.log('error:', error.response.data.error)
    throw Error(error.response?.data?.error?.message || 401)
  }
})

export const verifyOTP = createAsyncThunk('appReferenceNumber/verifyOTP', async (OTP) => {
  try {
  const response = await UseJwt.validateConfirmationCode(OTP)
  return response.data
  } catch (error) {
    console.log('error:', error)
    throw Error(error?.response?.data?.error?.message || 401)
  }
})

const initialState = {
  referenceNumber: "",
  phoneNumber: "",
  OTP: "",
  error: {
    message: null,
    name: null,
  },
  activeStep: "referenceNumber",
}

export const appReferenceNumberSlice = createSlice({
  name: 'appReferenceNumber',
  initialState,
  reducers: {
    setErrorState: (state, action) => {
      const { name, message } = action.payload
      state.error = { name, message }
    },
    setReferenceNumber: (state, action) => {
      state.referenceNumber = action.payload
    },
    setPhoneNumber: (state, action) => {
      state.phoneNumber = action.payload
    },
    setOTP: (state, action) => {
      state.OTP = action.payload
    }
  },
  // extraReducers: build => build
  // .addCase(verifyReferenceNumber.fulfilled, (state, action) => {
  //   state.referenceNumber = action.payload.jwt
  // })
})

export const { setErrorState, setReferenceNumber, setPhoneNumber, setOTP } = appReferenceNumberSlice.actions

export default appReferenceNumberSlice.reducer