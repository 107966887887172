import Actions from "../Types"

const initialState = {
  branch: "",
  date: "",
  time: "",
  data: {},
}
const returnToInitialState = () => {  
  return {
    branch: "",
    date: "",
    time: "",
    data: {},
  }

}
const MakeScheduleReducer = (state = initialState, { payload, type }) => {
  switch (type) {
    case Actions.MAKESCHEDULE:
      return {
        ...state,
      }
      case Actions.SCHEDULE:
        return {
          ...state,
          data: { ...payload },
        }
      case Actions.CLEARSTATE :
          return returnToInitialState()
    default:
      return state
  }
}

export default MakeScheduleReducer
