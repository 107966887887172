import { useDispatch } from 'react-redux'
import { setReferenceNumber } from '../store'
import { VerificationBox } from './'

const ReferenceInput = () => {
  const dispatch = useDispatch()

  return (
    <VerificationBox
      length={6}
      onChange={(e) => dispatch(setReferenceNumber(e))}
    />
  )
}

export default ReferenceInput
