import React, { useState, useEffect, useRef } from "react";
import { Box } from "@mui/system";
import { Stack, TextField, Typography } from "@mui/material";
import { useStyles } from "./MessageSectionStyle";
import Button from "../Button/Button";
import Menu from "@mui/material/Menu";
import AddIcon from "@mui/icons-material/Add";
import moment from "moment";
import { GetMessages } from "../../Store/Actions/GetMessages";
import { UserMessages } from "../../Store/Actions/UserMessages";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "./Actions";
import clsx from "clsx";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { UploadFile } from "@mui/icons-material";
import toast, { Toaster } from 'react-hot-toast'
import ClearIcon from '@mui/icons-material/Clear'
import { Interceptor } from "../../Utils/Interceptor";

const MessageSection = () => {
  const navigate = useNavigate()
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [messages, SetMessages] = useState("");
  const [uploadFileName,setUploadFileName] = useState("")
  const [file, setFile] = useState(null)
  const dispatch = useDispatch();
  const UploadFileRef = useRef()
  const AxiosInterceptor = Interceptor()
  const UploadFileClick = () => {
    UploadFileRef.current.click()
  }
  useEffect(() => {
    dispatch(GetMessages());
    const updateMessage = setInterval(() => {
      dispatch(GetMessages());
    }, 30 * 1000)
    return () => clearInterval(updateMessage);
  }, []);

  useEffect(() => {
    AxiosInterceptor.patch('/customer/notifications')
  }, [])

  const bankMessages = useSelector(
    (state) => state.GetMessagesReducer.BankMessages
  );
  const limit = (e) => {
    var filesize = (e.target.files[0].size / 1024 / 1024).toFixed(4);
    return filesize > 2;
  }

  const timedateHeader = (time, id, nextTime) => {
    if (
      moment(time).calendar().toString().includes("Today") &&
      !moment(nextTime).calendar().toString().includes("Today")
    ) {
      return (
        <Divider>
          <Chip label="Today" />
        </Divider>
      );
    }
  };

  const TodaysDate = moment().format("LL");

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)

      fileReader.onload = () => {
        resolve(fileReader.result)
      }

      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }
  const convertToFile = (theBlob, filename) => {
    if (theBlob != null) {
      var arr = theBlob.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    } else {
      return ""
    }
  };
  const handleFormSubmit = async () => {
    if (file) {
      let formData = {
        file: await convertBase64(file),
        fileName: file.name
      }
      if (messages != "")
        formData.message = messages

      dispatch(UserMessages({ ...formData }));

    } else {
      dispatch(UserMessages({ message: messages }));
    }
    SetMessages("");
    setUploadFileName("")
    setFile(null)
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (messages.length > 1) {
          // handleFormSubmit({ message: messages });
        }
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [messages]);

  useEffect(() => {
    const element = document.getElementById("messagebottom");
    element.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  }, [bankMessages]);

  return (
    <Box className={classes.MessageContainer}>
      <Typography className={classes.MessageHeading}>Messages</Typography>
      <Stack className={classes.Wrapper}>
        <Box className={classes.MessageListSectionContainer}>
          <Box className={classes.MessageListSection}>
            <Box className={classes.SubmitDate}>{TodaysDate}</Box>
            <Box className={classes.BoxWrapper}>
              {bankMessages?.map?.((mgs, i) => {
                return (
                  <React.Fragment key={i}>
                    {mgs.message &&
                      <Box className={classes.MessageBox}>
                        <Box
                          className={clsx(
                            mgs.sender === "customer"
                              ? classes.MessageList
                              : classes.MessageListTwo
                          )}

                        >
                          <Typography>{mgs.message}</Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.MessageDate}>
                            {moment(mgs.createdDate, "DD.MM.YYYY HH:mm").format(
                              "LLL"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    }
                    {
                      mgs.file &&
                      <Box className={classes.MessageBox}>
                        <Box
                          className={clsx(
                            mgs.sender === "customer"
                              ? classes.MessageList
                              : classes.MessageListTwo
                          )}

                        >
                          <Typography><a style={{ color: '#706F6F' }} href={mgs.file} download={mgs.fileName}>{mgs.fileName}</a></Typography>
                        </Box>
                        <Box>
                          <Typography className={classes.MessageDate}>
                            {moment(mgs.createdDate, "DD.MM.YYYY HH:mm").format(
                              "LLL"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    }
                  </React.Fragment>

                );
              })}
              <div id="messagebottom" />
            </Box>
          </Box>
        </Box>
        <Box className={classes.MenuContainer}>
          <Stack sx={{ width: "100%" }} direction="row" className={classes.SubmitInput}
            justifyContent="center"
            alignItems="center">
            <Box className={classes.AttachFile}>
              <Button

                variant="h7"
                className={classes.UploadFiles}
                endIcon={<AttachFileIcon />}
                onClick={UploadFileClick}


              />
              <input
                type="file"
                id="file"
                accept="application/pdf,image/*"
                onChange={(e) => {
                  if (limit(e)) {
                    e.target.value = null
                    setFile(null)
                    toast('Invalid file, limit 2MB')

                  } else {
                    setUploadFileName(e.target.files[0].name)
                    setFile(e.target.files[0])
                  }


                }}
                ref={UploadFileRef}
                style={{ display: "none" }}

              />
            </Box>
            <TextField
              fullWidth
              name="message"
              value={messages}
              onChange={(e) => SetMessages(e.target.value)}
              className={classes.MessageInput}
              multiline
              maxRows={5}
            />
          </Stack>
          <Button
            className={classes.SubmitBtn}
            Title="SUBMIT"
            type="submit"
            onClick={() => handleFormSubmit()}
          />
        </Box>
        <Stack>
        <p className={classes.uploadFileName}>
            {uploadFileName}
            <span>
              {
              uploadFileName == "" ? "" : 
                <ClearIcon className={classes.deleteFileIcon}
                  onClick={() => {
                    setUploadFileName("")
                    setFile(null)
                  }} />
              }
            </span>
          </p>
        </Stack>

        <Toaster toastOptions={{
          style: {
            background: 'red',
            color: '#fff',
          }
        }} />
      </Stack>
      <Box className={classes.GoBackBtn}>
        <Button Title="GO BACK TO THE LOAN APPLICATION" onClick={() => navigate(-1)} />
      </Box>

    </Box>
  );
};

export default MessageSection;
