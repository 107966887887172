import React, { useEffect, useRef, useState } from 'react'
import { useStyles } from './PersonalOnlineLoanStyle'
import { Box, Typography } from '@material-ui/core'
import Select from '../Inputs/Select'
import DatePicker from '../Inputs/DatePicker'
import TimePicker from '../Inputs/TimePickerLoanRequest'
import Button from '../Button/Button'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ScheduleRequest } from '../../Store/Actions/ScheduleRequest'
import Alert from '@mui/material/Alert'
import ScheduleThankYou from './SheduleThankYou'
import { baseUrl } from "../../baseUrl"
import moment from "moment"
import { getMinHours, hoursInterval, minDate, minTime, timeExpired } from '../../Utils/useMinDate'
import { toast, Toaster } from 'react-hot-toast'

const loadTime = (today) => {
  const newTime = minTime(moment(today).format('YYYY-MM-DDTHH:mm:ss'))
  if (newTime !== null) {
    return newTime
  }
}

const PersonalOnlineLoan = ({ message, amount, terms }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [errorTime, setErrorTime] = useState(false)
  const [errorDate, setErrorDate] = useState(false)


  // console.log(errorTime);

  const { dataSchedule: scheduleData } = useSelector(
    (state) => state.ScheduleRequestReducer
  )
  const [showScheduleThankyou, setShowScheduleThankyou] = useState(false)
  const [dataSchedule, setDataSchedule] = useState({
    branchId: '',
    date: minDate('14:00:00Z'),
    time: '',
  })
  const [valueTime, setValueTime] = useState(getMinHours(dataSchedule.date, hoursInterval))
  const countPage = useRef(0)


  useEffect(() => {
    if (countPage.current === 0 ) {
      // loadTime()
      countPage.current = 1
    }
    
  }, [])

  useEffect(() => {
    if (scheduleData.emailSent ?? false) {
      setShowScheduleThankyou(true)
    }

  }, [scheduleData])

  useEffect(() => {
    setDataSchedule({ ...dataSchedule, time: valueTime })
  }, [valueTime])

  const handleSubmit = () => {
    console.log(moment(dataSchedule.date).format())
    console.log(moment(dataSchedule.time).format())
    console.log(dataSchedule)
    if (!(Number(amount) >= 1000)) return toast.error('Please select an amount greater than or equal to 1000')
    if (!(Number(terms) >= 12)) return toast.error('Please select a term greater than or equal to 12')

    if (errorTime || errorDate) return toast.error("Please select a valid date and time.")

    if (dataSchedule.branchId === '' || dataSchedule.date === '' || dataSchedule.time === '') {
      return toast.error('Please complete all the required fields.')
    }
    const data = {
      branch: dataSchedule.branchId,
      appointmentDate: moment(dataSchedule.date).format(),
      appointmentTime: moment(dataSchedule.time).format(),
    }
    dispatch(ScheduleRequest({ ...data }))
  }

  return (
    <Box mt={4}>
      <Toaster />
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        className={classes.OnlineLoanContainer}
      >
        <Box className={classes.OnlineLoanText}>
          <Typography>
          Personal online loans for more than {message} will require in-branch verification and approval.
           Please feel free to schedule your appointment NOW
      
          </Typography>
        </Box>
        <Stack
          direction="column"
          spacing={3}
          mt={4}
          className={classes.OnlineLoanInput}
        >
          <Select
            Label="SELECT YOUR PREFERRED BRANCH"
            placeholder="Dangriga Branch"
            style={{ backgroundColor: '#ffff' }}
            ApiUrl={`${baseUrl}/branches`}
            name="branchId"
            required
            onChange={(e, value) =>
              setDataSchedule({ ...dataSchedule, branchId: value.id })
            }
          />

          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={{ xs: 1, sm: 2, md: 4 }}
          >
            <DatePicker
              Label="SELECT APPOINTMENT DATE"
              openTo="day"
              views={['year', 'month', 'day']}
              style={{ backgroundColor: '#ffff' }}
              disablePast={true}
              onChange={(e) => setDataSchedule({ ...dataSchedule, date: e })}
              disableFuture={false}
              value={dataSchedule.date}
              minDate={minDate('14:00:00Z')}
              onError={(error) => setErrorDate(error)}
              name="appointmentDate"
            />
            <TimePicker
              Label=" SELECT APPOINTMENT TIME"
              placeholder="8:00AM"
              style={{ backgroundColor: '#ffff' }}
              name="appointmentTime"
              appointmentDate={dataSchedule.time}
              minTime={getMinHours(dataSchedule.date, hoursInterval)}
              onError={(error) => setErrorTime(error)}
              // minTime={loadTime(moment().format()) || '2022-12-12T08:00'}
              onChange={(e) => {
                setValueTime(e)
              }}
            />
          </Stack>
        </Stack>
      </Stack>
      {!showScheduleThankyou  ? (
        <Box className={classes.Btn}>
          <Link
            // to="/NonCustomerPage/ScheduleAppointment"
            className={classes.Links}
          >
            <Button
              className={classes.ScheduleBtn}
              backgroundColor="#005FAA"
              Title="SCHEDULE"
              BtnVariant="contained"
              onClick={handleSubmit}
            />
          </Link>
        </Box>
      ) : null}
      {showScheduleThankyou ? (
        <Box>
          <Alert
            icon={false}
            sx={{ backgroundColor: '#D3E4F6' }}
            style={{ padding: 30, marginTop: '40px' }}
          >
            <strong className={classes.title}>
              To ensure a smooth processing of your loan request, please bring
              along the following documents :
            </strong>{' '}
            <br />
            <ul>
              <li className={classes.li}>Employment letter</li>
              <li className={classes.li}>Two recent pay slips</li>
              <li className={classes.li}>
                Tax returns{' '}
                <span className={classes.span}>(for self-employed)</span>
              </li>
            </ul>
          </Alert>
          <Box>
            <ScheduleThankYou />
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}

export default PersonalOnlineLoan
